import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import {
  setCompanyLogoSetting, setIsTestCompany,
  setUteSettings
} from "../slices/company-slice";
import { setCompanyProfile, setCompanyOfficeLocations, setCommonSettings,setCompanySignatureLink } from "../slices/company-slice";
import { ICompany, ITaxSoftwareSettings } from "common/model/company";
import { AppNotifier } from "common/components/toast/Toast";
import { RequestNotification } from "helper/Constants";
import { IUteSettings } from "common/model/company-settings";
import { AxiosResponse } from "axios";

export const requestCompanyOfficeLocations = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.COMPANY_MANAGEMENT.GET_COMPANY_LOCATIONS}`, {
    method: "GET",
    credentials: "include"
  })
    .then((response) => response.data as ICompany)
    .then((data) => {
      dispatch(setCompanyOfficeLocations(data.officeLocations));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    });
};

export const requestCompanyProfile = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.COMPANY_MANAGEMENT.GET_COMPANY_PROFILE}`, {
    method: "GET",
    credentials: "include"
  })
    .then((response) => response)
    .then((data) => {
      let records = data.data.value.company as ICompany;
      dispatch(setCompanyProfile(records));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    });
};

export const requestCompanySignatureLink = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.COMPANY_MANAGEMENT.GET_COMPANY_SIGNATURE_LINK}`, {
    method: "GET",
    credentials: "include"
  })
    .then((response) => response)
    .then((data: any) => {
      dispatch(setCompanySignatureLink(data.data.sas));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    });
};
export const requestCompanyLogo = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.COMPANY_MANAGEMENT.GET_COMPANY_LOGO}`)
    .then((response) => response)
    .then((data: any) => {
      let {isSsrlogo,logoPath}=data.data;
      const isSsrLogoPayload = isSsrlogo == "True" ? true : false;
      isSsrlogo = false;
      dispatch(setCompanyLogoSetting({ logoPath: logoPath, isSsrLogo: isSsrLogoPayload }));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    });
};
export const requestCommonSettings= (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        Axios.get(`${API.COMPANY_MANAGEMENT.GET_COMMON_SETTINGS}`, {
          method: "GET",
          credentials: "include"
        })
          .then((response) => response)
          .then((data) => {
            let records = data.data.commonSettings.taxSoftwareSetting as ITaxSoftwareSettings;
            dispatch(setCommonSettings(records));
          })
          .catch(() => {
            AppNotifier.Error(RequestNotification.Failure.AllGathers);
          });
}
export const requestIsTestCompany= (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        Axios.get(`${API.COMPANY_MANAGEMENT.IS_TEST_COMPANY}`, {
          method: "GET",
          credentials: "include"
        })
          .then((response) => response)
          .then((data) => {
            let isTestCompany = data.data.isTestCompany;
            dispatch(setIsTestCompany(isTestCompany));
          });
}

export const getUTESettings = (callback?: (data: IUteSettings)=>void): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.COMPANY_SETTINGS.GET_UTE_SETTINGS}`, {
    credentials: "include"
  })
    .then((response: AxiosResponse<IUteSettings>) => {
      callback && callback(response.data);
      dispatch(setUteSettings(response.data));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    });
};