import { ClientType, ControlRole, ControlType, DataType, DefaultSenderInfoType, EngagementType, EngagementTypeString, GroupType } from "common/enum/GatherEnums";
import { BookmarkPage, Bookmarks, BoundingRectangle, Control, DateControl, FormGroup, OrganizerBookmarkPage, OrganizerBookmarks, OrganizerForm, QuestionControl, SectionControl, Signature, SignatureCheckBoxControl, SignatureRadioButtonControl, TextPlacement, Textbox } from "common/model/GatherDocumentModel";
import ControlBase from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { Guid } from "./Guid";
import { DeliveryOptionsConstant, HelpTextConstant } from "pages/Constants";
import { ClientInfoTabConstants } from "./Constants";
import { GatherDocumentSetting } from "common/model/gather-document-process-model-types";
import { IDropdown } from "common/model/company-model";
import { GatherClientDetail, GatherInfo } from "common/model/batch-gather-document-process-model";
import { GatherEventWithDescription } from "common/model/GatherDocumentModel";
import { AppNotifier } from "common/components/toast/Toast";
import { ISelectedTemplate } from "common/model/custom-questions";

export const getBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup.type === type);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const bookmarkPages = formGroups && formGroups[index].forms.map(form => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark
        } as BookmarkPage
    });

    return {
        heading: heading,
        pages: bookmarkPages
    }
}

export const getOrganizerBookmarks = (formGroups: FormGroup[], heading: string): OrganizerBookmarks => {
    let bookmarks: OrganizerBookmarkPage[] = []
    const organizerBookmarks = getOrganizerBookmarksByType(formGroups, GroupType.Organizer);
    const organizerWithSignBookmarks = getOrganizerBookmarksByType(formGroups, GroupType.OrganizerWithSignature);
    if (organizerBookmarks) {
        bookmarks = organizerBookmarks.concat(organizerWithSignBookmarks);
    }
    bookmarks.sort((a: OrganizerBookmarkPage, b: OrganizerBookmarkPage) => {
        return a.pageNo - b.pageNo;
    })
    return bookmarks.length == 0 ? {
        heading: "",
        pages: []
    } : {
        heading: heading,
        pages: bookmarks
    }
}

export const getEngagementLetterBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup.type === type);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const bookmarkPages = formGroups && formGroups[index].forms?.map(form => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark
        } as BookmarkPage
    })
    return {
        heading: heading,
        pages: bookmarkPages
    }
}

export const getOrganizerBookmarksByType = (formGroups: FormGroup[], type: GroupType, sourceDocumentEnabled: boolean = true): OrganizerBookmarkPage[] => {
    let bookmarkPages: any = [];
    if (formGroups) {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (index === -1) {
            return []
        }
        bookmarkPages = formGroups[index].forms?.map(form => {
            const organizerform = form as OrganizerForm;
            return {
                pageNo: form.pageNo,
                pageTitle: form.bookmark,
                enabledSourceDocument: sourceDocumentEnabled && organizerform.enabledSourceDocument,
                enableSignature: organizerform.enableSignature

            } as OrganizerBookmarkPage
        });
    }
    return bookmarkPages;
}

export function populateClientInfo(gatherClients: GatherClientDetail[], clientType: ClientType): any {
    const client = gatherClients?.find(client => client.clientType === clientType);
    if (client) {
        return {
            name: client.name.trim(),
            emailAddress: client.emailAddress,
            mobileNumber: client.mobileNumber,
            countryCode: client.countryCode,
            isDeceased: client.isDeceased
        };
    } else {
        return {
            name: '',
            emailAddress: '',
            mobileNumber: '',
            countryCode: '',
            isDeceased: false
        };
    }
}

export function getGatherTaxYearList(): IDropdown[] {
    const minTaxYear = 2023;
    let maxTaxYear = new Date().getFullYear() - 1;
    if (new Date().getMonth() == 11) { // If Current month is Dec then add current year in tax year list
        maxTaxYear++;
    }
    let gatherYears: IDropdown[] = [];
    for (let i = maxTaxYear; i >= minTaxYear; i--) {
        gatherYears.push({
            value: i,
            label: i.toString()
        })
    }
    return gatherYears;
}

export const getOrganizerDeletedPages = (formGroups: FormGroup[],): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup?.type === GroupType.OrganizerRemoved);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const deletedPages: any = formGroups && formGroups[index]?.forms?.map((form: any) => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark,

        } as BookmarkPage
    })
    return {
        heading: "Removed",
        pages: deletedPages
    }
}
export const getESignDeletedPages = (formGroups: FormGroup[],): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup?.type === GroupType.Removed);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const deletedPages: any = formGroups && formGroups[index]?.forms?.map((form: any) => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark,

        } as BookmarkPage
    })
    return {
        heading: "Removed",
        pages: deletedPages
    }
}

export const getPages = (bookmarks: Bookmarks): number[] => {
    return bookmarks.pages?.map(page => page.pageNo);
}

const TopAdjustmentForSignatureControl = 12;
export const getTopAdjustmentSignatureControlValue = (controlType: ControlType): number => {
    switch (controlType) {
        case ControlType.SignatureCheckBoxControl:
        case ControlType.SignatureRadioButtonControl:
            return 0;
        default:
            return TopAdjustmentForSignatureControl;
    }
}

export const validatePhone = (phoneNo: string) => {
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    else
        return true;
}
export const isValidTenDigitNumber=(value: string)=> {
    const pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(value);
}

export const validateCountryCode =(taxpayerCountryCode : string |undefined) =>{
      var isvalidContryCode = false;
    if(taxpayerCountryCode?.trim()){
        var countryCodeOption = []
        countryCodeOption =  GetCountryCode();
        countryCodeOption.forEach(function (x) {
            if(x.value == "+"+taxpayerCountryCode || x.value == taxpayerCountryCode){
                isvalidContryCode = true;
                return isvalidContryCode;
            }
        })
       
       }
    return isvalidContryCode;
}

export function isValidEmailAddress(emailAddress: string, trim: boolean = false) {
    let email = emailAddress;
    if (trim) {
        email = email.trimLeft();
        email = email.trimRight();
    }
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(email);
};

export function reOrderQuestionnaireData(customQuestions: ISelectedTemplate | null) {
    if (!customQuestions) {
        return null;
    }
    let reOrderedCustomQuestions = JSON.parse(JSON.stringify(customQuestions));
    reOrderedCustomQuestions?.sections?.forEach((section: any) => {
        section.sectionItems?.forEach((question: any, index: number) => {
            question.order = index + 1;
        });
    });

    return reOrderedCustomQuestions;
}

export function getControlText(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "Date";
        case ControlType.Signature:
            return "Signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Initial:
                        return "Initials";
                    case DataType.Name:
                        return "Name";
                    case DataType.Email:
                        return "Email";
                    case DataType.Text:
                        return "Text";
                    case DataType.Title:
                        return "Title";
                    case DataType.CompanyField:
                        return "Company Field";
                    case DataType.SSN:
                        return "Signature Stamp";
                    default:
                        return "";
                }
            }
        case ControlType.SignatureCheckBoxControl:
            return "Checkbox";
        case ControlType.SignatureRadioButtonControl:
            return "Radio Button";
        default:
            return "";
    }
}

export function getControlIcon(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "fas fa-calendar-alt";
        case ControlType.Signature:
            return "fas fa-signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Email:
                        return "fas fa-envelope";
                    case DataType.Initial:
                        return "fas fa-font";
                    case DataType.Name:
                        return "fas fa-user-circle";
                    case DataType.Text:
                        return "fas fa-text-width";
                    default:
                        return "";
                }
            }
        case ControlType.SignatureCheckBoxControl:
            return "fas fa-check-square";
        case ControlType.SignatureRadioButtonControl:
            return "fas fa-dot-circle";
        default:
            return "";
    }
}

export function getControlTopPositionPDFJS(page: number, top: number, scale: number): number {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;
    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;

    top = pageHeight - top;
    top = (top / topRatio) - 5;

    return top;

}

export function getRadioButtonLeftAdjustmentValue(question: QuestionControl): number {
    if (question.parentQuestionId && question.parentQuestionId !== Guid.empty && question.sectionId && question.sectionId !== Guid.empty)
        return 20;
    else if (question.parentQuestionId && question.parentQuestionId !== Guid.empty || question.sectionId && question.sectionId !== Guid.empty)
        return 13.3;
    return 6.6;
}

export function getControlHeight(control: Control): number {
    let height = 0;
    switch (control.controlType) {
        case ControlType.QuestionControl: {
            const questionControl = control as QuestionControl;
            height = questionControl.textbox.boundingRectangle.height;
            if (questionControl.inputTextbox && questionControl.inputTextbox?.boundingRectangle?.height) {
                height += questionControl.inputTextbox.boundingRectangle.height;
            }
            return height;
        }
        case ControlType.Section: {
            const sectionControl = control as SectionControl;
            return sectionControl.section.boundingRectangle.height;
        }

    }
    return height;
}
export const getControl = (controlType: ControlType, dataType: DataType, left: number, top: number): Control => {
    switch (controlType) {
        case ControlType.Date:
            return {
                controlType: controlType,
                textPlacement: TextPlacement.Left,
                required: true
            } as DateControl;
        case ControlType.Signature:
            return {
                controlType: controlType
            } as Signature;
        case ControlType.Textbox:
            return {
                controlType: controlType,
                dataType: dataType,
                textPlacement: TextPlacement.Left,
                required: dataType !== DataType.CompanyField
            } as Textbox;
        case ControlType.SignatureCheckBoxControl:
            return {
                controlType: controlType,
                dataType: dataType,
                required: true,
                tooltip: '',
                page: 0,
                disabled: false,
                items: [],
                id: '',
                signerId: 0,
                controlRole: ControlRole.None,
                readonly: false,
                boundingRectangle: getDefaultCheckBoxBoundingRectangle(left, top),
                name: '',
                customData: ''
            } as SignatureCheckBoxControl;
        case ControlType.SignatureRadioButtonControl:
            return {
                controlType: controlType,
                dataType: dataType,
                required: true,
                tooltip: '',
                page: 0,
                disabled: false,
                items: [],
                id: '',
                signerId: 0,
                controlRole: ControlRole.None,
                readonly: false,
                boundingRectangle: getDefaultRadioButtonBoundingRectangle(left, top),
                name: '',
                customData: ''
            } as SignatureRadioButtonControl;
        default:
            return {} as Control;
    }
}
export const getSignatureControls = (): any[] => {
    return [
        {
            caption: 'Signature',
            controlType: ControlType.Signature,
            dataType: DataType.None,
            helpText: HelpTextConstant.ClientSignature
        },
        {
            caption: 'Initials',
            controlType: ControlType.Textbox,
            dataType: DataType.Initial,
            helpText: HelpTextConstant.Initial
        },
        {
            caption: 'Date Signed',
            controlType: ControlType.Date,
            dataType: DataType.None,
            helpText: HelpTextConstant.SignatureDate
        }];
}
export const getTextControls = (): any[] => {
    return [
        {
            caption: 'Print Name',
            controlType: ControlType.Textbox,
            dataType: DataType.Name,
            helpText: HelpTextConstant.Name
        },
        {
            caption: 'Company Field',
            controlType: ControlType.Textbox,
            dataType: DataType.CompanyField,
            helpText: HelpTextConstant.Title
        },
        {
            caption: 'Title',
            controlType: ControlType.Textbox,
            dataType: DataType.Title,
            helpText: HelpTextConstant.Title
        },

        {
            caption: 'E-Mail',
            controlType: ControlType.Textbox,
            dataType: DataType.Email,
            helpText: HelpTextConstant.Email
        }
    ];
}
export const getFormControls = () => {
    return [
        {
            caption: 'Text',
            controlType: ControlType.Textbox,
            dataType: DataType.Text,
            helpText: HelpTextConstant.Title
        }
        , {
            caption: 'Check Box',
            controlType: ControlType.SignatureCheckBoxControl,
            dataType: DataType.None,
            helpText: HelpTextConstant.Checkbox
        },
        {
            caption: 'Radio Button',
            controlType: ControlType.SignatureRadioButtonControl,
            dataType: DataType.None,
            helpText: HelpTextConstant.RadioButton
        }];
};

export const getEroControls = () => {
    return [
        {
            caption: 'Signature Stamp',
            controlType: ControlType.Signature,
            dataType: DataType.None,
            helpText: HelpTextConstant.ClientSignature
        }];
};

export function getDefaultCheckBoxBoundingRectangle(left: number, top: number): BoundingRectangle {
    return {
        left: left,
        top: top,
        width: 200,
        height: 100
    } as BoundingRectangle;
}
export function getDefaultRadioButtonBoundingRectangle(left: number, top: number): BoundingRectangle {
    return {
        left: left,
        top: top,
        width: 200,
        height: 100
    } as BoundingRectangle;
}

export function GetCountryCode() {
    var CountryCodeOption = [];
    CountryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
    CountryCodeOption.push({ value: "+93", label: "Afghanistan (+93)" });
    CountryCodeOption.push({ value: "+355", label: "Albania (+355)" });
    CountryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
    CountryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
    CountryCodeOption.push({ value: "+244", label: "Angola (+244)" });
    CountryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
    CountryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
    CountryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
    CountryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
    CountryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
    CountryCodeOption.push({ value: "+61", label: "Australia (+61)" });
    CountryCodeOption.push({ value: "+43", label: "Austria (+43)" });
    CountryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
    CountryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
    CountryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
    CountryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
    CountryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
    CountryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
    CountryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
    CountryCodeOption.push({ value: "+501", label: "Belize (+501)" });
    CountryCodeOption.push({ value: "+229", label: "Benin (+229)" });
    CountryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
    CountryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
    CountryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
    CountryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
    CountryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
    CountryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
    CountryCodeOption.push({ value: "+246", label: "British Indian Ocean Territory (+246)" });
    CountryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
    CountryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
    CountryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
    CountryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
    CountryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
    CountryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
    CountryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
    CountryCodeOption.push({ value: "+599", label: "Caribbean Netherlands (+599)" });
    CountryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
    CountryCodeOption.push({ value: "+236", label: "Central African Republic (+236)" });
    CountryCodeOption.push({ value: "+235", label: "Chad (+235)" });
    CountryCodeOption.push({ value: "+56", label: "Chile (+56)" });
    CountryCodeOption.push({ value: "+86", label: "China (+86)" });
    CountryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
    CountryCodeOption.push({ value: "+243", label: "Congo (+243)" });
    CountryCodeOption.push({ value: "+242", label: "Congo (+242)" });
    CountryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
    CountryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
    CountryCodeOption.push({ value: "+225", label: "Côte d’Ivoire (+225)" });
    CountryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
    CountryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
    CountryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
    CountryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
    CountryCodeOption.push({ value: "+420", label: "Czech Republic (+420)" });
    CountryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
    CountryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
    CountryCodeOption.push({ value: "+1767", label: "Dominica (+1767)" });
    CountryCodeOption.push({ value: "+1809", label: "Dominican Republic (+1809)" });
    CountryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
    CountryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
    CountryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
    CountryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
    CountryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
    CountryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
    CountryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
    CountryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
    CountryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
    CountryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
    CountryCodeOption.push({ value: "+358", label: "Finland (+358)" });
    CountryCodeOption.push({ value: "+33", label: "France (+33)" });
    CountryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
    CountryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
    CountryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
    CountryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
    CountryCodeOption.push({ value: "+995", label: "Georgia (+995)" });
    CountryCodeOption.push({ value: "+49", label: "Germany (+49)" });
    CountryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
    CountryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
    CountryCodeOption.push({ value: "+30", label: "Greece (+30)" });
    CountryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
    CountryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
    CountryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
    CountryCodeOption.push({ value: "+1671", label: "Guam (+1671)" });
    CountryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
    CountryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
    CountryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
    CountryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
    CountryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
    CountryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
    CountryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
    CountryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
    CountryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
    CountryCodeOption.push({ value: "+91", label: "India (+91)" });
    CountryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
    CountryCodeOption.push({ value: "+98", label: "Iran (+98)" });
    CountryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
    CountryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
    CountryCodeOption.push({ value: "+972", label: "Israel (+972)" });
    CountryCodeOption.push({ value: "+39", label: "Italy (+39)" });
    CountryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
    CountryCodeOption.push({ value: "+81", label: "Japan (+81)" });
    CountryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
    CountryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
    CountryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
    CountryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
    CountryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
    CountryCodeOption.push({ value: "+383", label: "Kosovo (+383)" });
    CountryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
    CountryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
    CountryCodeOption.push({ value: "+856", label: "Laos (+856)" });
    CountryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
    CountryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
    CountryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
    CountryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
    CountryCodeOption.push({ value: "+218", label: "Libya (+218)" });
    CountryCodeOption.push({ value: "+423", label: "Liechtenstein (+423)" });
    CountryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
    CountryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
    CountryCodeOption.push({ value: "+853", label: "Macao (+853)" });
    CountryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
    CountryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
    CountryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
    CountryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
    CountryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
    CountryCodeOption.push({ value: "+223", label: "Mali (+223)" });
    CountryCodeOption.push({ value: "+356", label: "Malta (+356)" });
    CountryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
    CountryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
    CountryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
    CountryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
    CountryCodeOption.push({ value: "+230", label: "Mauritius (+230)" });
    CountryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
    CountryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
    CountryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
    CountryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
    CountryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
    CountryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
    CountryCodeOption.push({ value: "+382", label: "Montenegro (+382)" });
    CountryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
    CountryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
    CountryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
    CountryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
    CountryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
    CountryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
    CountryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
    CountryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
    CountryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
    CountryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
    CountryCodeOption.push({ value: "+227", label: "Niger (+227)" });
    CountryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
    CountryCodeOption.push({ value: "+683", label: "Niue (+683)" });
    CountryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
    CountryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
    CountryCodeOption.push({ value: "+47", label: "Norway (+47)" });
    CountryCodeOption.push({ value: "+968", label: "Oman (+968)" });
    CountryCodeOption.push({ value: "+92", label: "Pakistan (+92)" });
    CountryCodeOption.push({ value: "+680", label: "Palau (+680)" });
    CountryCodeOption.push({ value: "+970", label: "Palestine (+970)" });
    CountryCodeOption.push({ value: "+507", label: "Panama (+507)" });
    CountryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
    CountryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
    CountryCodeOption.push({ value: "+51", label: "Peru (+51)" });
    CountryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
    CountryCodeOption.push({ value: "+48", label: "Poland (+48)" });
    CountryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
    CountryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
    CountryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
    CountryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
    CountryCodeOption.push({ value: "+40", label: "Romania (+40)" });
    CountryCodeOption.push({ value: "+7", label: "Russia (+7)" });
    CountryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
    CountryCodeOption.push({ value: "+1784", label: "Saint Vincent and the Grenadines (+1784)" });
    CountryCodeOption.push({ value: "+685", label: "Samoa (+685)" });
    CountryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
    CountryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
    CountryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
    CountryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
    CountryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
    CountryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
    CountryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
    CountryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
    CountryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
    CountryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
    CountryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
    CountryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
    CountryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
    CountryCodeOption.push({ value: "+211", label: "South Sudan (+211)" });
    CountryCodeOption.push({ value: "+34", label: "Spain (+34)" });
    CountryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
    CountryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
    CountryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
    CountryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
    CountryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
    CountryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
    CountryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
    CountryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
    CountryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
    CountryCodeOption.push({ value: "+963", label: "Syria (+963)" });
    CountryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
    CountryCodeOption.push({ value: "+992", label: "Tajikistan (+992)" });
    CountryCodeOption.push({ value: "+255", label: "Tanzania (+255)" });
    CountryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
    CountryCodeOption.push({ value: "+228", label: "Togo (+228)" });
    CountryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
    CountryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
    CountryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
    CountryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
    CountryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
    CountryCodeOption.push({ value: "+1649", label: "Turks Caicos Islands (+1649)" });
    CountryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
    CountryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
    CountryCodeOption.push({ value: "+44", label: "UK (+44)" });
    CountryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
    CountryCodeOption.push({ value: "+971", label: "United Arab Emirates (+971)" });
    CountryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
    CountryCodeOption.push({ value: "+998", label: "Uzbekistan (+998)" });
    CountryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
    CountryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
    CountryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
    CountryCodeOption.push({ value: "+84", label: "Virgin Islands" });
    CountryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
    CountryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
    CountryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
    CountryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
    CountryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
    return CountryCodeOption;
}

export const validateLocationData = (organizerDocument: GatherInfo): boolean => {
    if (organizerDocument && (organizerDocument.locationId == undefined || organizerDocument.locationId <= 0)) {
        AppNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.LocationRequired);
        return false;
    }
    return true;
}

export const validateEROData = (organizerDocument: GatherInfo): boolean => {
    if (organizerDocument && (organizerDocument.ero == undefined || organizerDocument.ero <= 0)) {
        AppNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.ERORequired);
        return false;
    }
    return true;
}

export const validateClientEmailAddress = (gatherClients: GatherClientDetail[] | null): boolean => {
    if (gatherClients) {
        for (let i = 0; i < (gatherClients?.length ?? 0); i++) {
            let client = gatherClients[i];
            if (client?.emailAddress !== null && client?.emailAddress !== "" && !isValidEmailAddress(client?.emailAddress, true)) {
                AppNotifier.Warning(`Please enter valid ${ClientType[client?.clientType].toString()} Email Id`);
                return false;
            }
        }
    }
    return true;
}

export const validateEngagementData = (organizerDocument: GatherInfo): boolean => {
    const type: string = organizerDocument?.engagementType == null || organizerDocument?.engagementType == "None" ? "None" : EngagementType[organizerDocument?.engagementType || EngagementType.E1040];
    if(organizerDocument && (type === EngagementTypeString.None || null )){
        AppNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.EngagementTypeRequired);
        return false;
    }
    return true;
}

export const validateContactPerson = (documentSetting: GatherDocumentSetting): boolean => {
    if (!documentSetting?.deliverySetting?.contactPersonId) {
        AppNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.ContactPersonRequired, null);
        return false;
    }
    return true;
}

export const validateOrganizerClient = (client: GatherClientDetail): boolean => {
    if (client) {
        const { clientType, emailAddress, zip, city, mobileNumber, countryCode, name, isDeceased, signingOrder } = client;
        const clientTypeString: string = ClientType[clientType]?.toString();
        
        if (!name) {
            AppNotifier.Warning(`Please enter ${clientTypeString} Name`);
            return false;
        }
        if (emailAddress === null || emailAddress === ""){
            AppNotifier.Warning(`${clientTypeString} Email Id is missing`);
            return false;
        }
        if (emailAddress && !isValidEmailAddress(emailAddress, true)) {
            AppNotifier.Warning(`Please enter valid ${clientTypeString} Email Id`);
            return false;
        }
        if (mobileNumber && !validatePhone(mobileNumber)) {
            AppNotifier.Warning(`Please enter valid mobile number for ${clientTypeString}`);
            return false;
        }
        if (mobileNumber && !countryCode) {
            AppNotifier.Warning(`Please select a country code for ${clientTypeString}`);
            return false;
        }
        if (!mobileNumber && countryCode) {
            AppNotifier.Warning(`Please enter valid mobile number for ${clientTypeString}`);
            return false;
        }
    }
    return true;
}

export const validateOrganizerDocumentSettings = (
    settings: GatherDocumentSetting, 
    ELEnabled: boolean, 
    organizerEnabled: boolean, 
    uploadFileEnabled: boolean) => {
    const { deliverySetting: {
        contactPersonId,
        preparerMessage,
        sender
    },
        notificationSetting: {
            notifyELCompletedUser,
            notifyOrganizerCompletedUser,
            notifyUploadedFilesUser
        }
    } = settings;

    if (contactPersonId <= 0) {
        AppNotifier.Warning(DeliveryOptionsConstant.SelectContactPerson);
        return false;
    }
    if ((!sender || (sender.senderType !== DefaultSenderInfoType.Company && !sender.senderId) ||
        (sender.senderType === DefaultSenderInfoType.Company && sender.senderId === null && sender.senderId === undefined))) {
        AppNotifier.Warning(DeliveryOptionsConstant.SelectSenderName);
        return false;
    }

    if (organizerEnabled && (!notifyOrganizerCompletedUser || notifyOrganizerCompletedUser.length <= 0)) {
        AppNotifier.Warning(DeliveryOptionsConstant.OrgCompletedNotificationUser);
        return false;
    }

    if (ELEnabled && (!notifyELCompletedUser || notifyELCompletedUser.length <= 0)) {
        AppNotifier.Warning(DeliveryOptionsConstant.ELCompletedNotificationUser);
        return false;
    }
    if (uploadFileEnabled && (!notifyUploadedFilesUser || notifyUploadedFilesUser.length <= 0)) {
        AppNotifier.Warning(DeliveryOptionsConstant.FilesUploadedNotificationUser);
        return false;
    }
    return true;
}

export const GetGatherEventDescription = (eventName: string): string => {
    switch (eventName) {
      case 'Created':
      case 'Emailed':
      case 'OTPAuthenticationFailed':
      case 'Reminder':
      case 'ResentAccessLink': 
      case 'EngagementLetterEsigned':
      case 'FilledOrganizer':  
      case 'CompletedOrganizer':
      case 'UploadedDocument':
      case 'CpaOrganizerDownloaded':
      case 'CpaEngagementDownloaded':
      case 'CpaSourceDocumentDownloaded':
      case 'ClientOrganizerDownloaded':
      case 'ClientEngagementLetterDownloaded':  
      case 'DeleteSourceDocument':
      case 'OTPEmailed':
      case 'OTPTexted':
      case 'OTPAuthenticated':
      case 'EngagementLetterViewed':
      case 'EngagementLetterCompleted':
      case 'EngagementLetterReviewed':
      case 'ManualSigningReminder':
      case 'ManualOrganizerReminder':
      case 'SourceDocumentReminder':
      case 'AccessCodeEmail':
      case 'OrganizerSendForReview':
      case 'OrganizerReviewed':
      case 'CpaEngagementLetterManuallySigned':
      case 'CpaOrganizerManuallyCompleted':
      case 'SourceDocumentUploadMarkedCompleted':
      case 'SourceDocumentUploadReopened':
      case 'ArchivedGather':
      case 'RestoreArchivedGather':
      case 'RecycleGather':
      case 'RestoreRecycledGather':
      case 'AutomaticSigningReminder':
      case 'AutomaticOrganizerReminder':
      case 'ReopenGather':
      case 'ClosedGatherByFirm':
      case 'ReopenedGatherByFirm':
      case 'CustomQuestionFilled':
      case 'CustomQuestionCompleted':
      case 'CpaDownloadedOrganizerWithCustomQuestions':  
      case 'SourceDocumentUploadProgressNotified':
      case 'K1DocumentUploadProgressNotified':
      case 'TpSpouseEmailAddressUpdated':
      case 'OfficeLocationUpdated':
      case 'TpSpouseNameUpdated':
      case 'SecondSignerEmailAddressUpdated':
      case 'AccessCodeTexted':     
      case 'CpaMergedSourceDocumentDownloaded':
      case 'UtePINValidationSuccess':
      case 'UteOTPMailDelivered':
      case 'UteOTPAuthenticationFailed':
      case 'UteOTPAccessLocked':
      case 'UtePINAuthenticationFailed':
      case 'UtePINAccessLocked':
      case 'UteResetPINRequested':
      case 'UteNewPINGenerated':
      case 'UteLoginSuccess':
      case 'UteMergedEmailId':
      case 'UteUnMergedEmailId':
      case 'UtePINUpdated':
      case 'UteEmailIDUpdated':
      case 'UteDocumentDownloadedFromPreview':
      case 'FilledCustomQuestionsAutoSave':
      case 'FilledOrganizerAutoSave':
        return GatherEventWithDescription[eventName];
     default:
        return eventName.toString();
    }
  }
  