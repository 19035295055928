import { EngagementType } from "common/enum/GatherEnums";
import { EngagementLetterStatus, OrganizerStatus, SignatureStatus, SourceDocumentStatus, GatherFilterType, IGatherFilter, SortDirections } from "./GatherDocumentModel";
import { IGatherDocument } from "./gather-document-table";

export interface IGatherTableModel {
    count: number;
    documents: IDeliveredGridGatherDocument[];
}

export enum GatherStatus {
    None = 0,
    Uploaded = 1,
    ProcessingUpload = 2,
    ErrorProcessingUpload = 3,
    Ready = 4,
    InProcess = 5,
    DeliveryInProgress = 6,
    DeliveryFailed = 7,
    Delivered = 8,
    Completed = 9
}

export enum TaxSoftwareType {
    None = -1,
    ProSystems = 0,
    UltraTax = 1,
    GoSystem = 2,
    UltraTaxM18 = 3,
    Lacerte = 4,
    Drake = 5
}

export interface IDeliveredGridGatherDocument extends IGatherDocument {
    id: number;
    uniqueId: string;
    hasCustomQuestion: boolean;
    status: GatherStatus;
    deliveredOn: Date;
    engagementLetterStatus: EngagementLetterStatus;
    organizerStatus: OrganizerStatus;
    previousOrganizerStatus: OrganizerStatus;
    sourceDocumentStatus: SourceDocumentStatus;
    taxSoftware?: TaxSoftwareType;
    eroUserName: string;
    sentByUserName: string;
    taxpayerName: string;
    isDocumentLocked: boolean;
    signerCount: number;
    isTPGatherMailDropped: boolean;
    isSpouseGatherMailDropped: boolean;
    taxpayerEmail: string;
    spouseEmail: string;
    uploadedSourceDocumentsCount: number;
    downloadedSourceDocumentsCount: number;
    isSourceDocumentUploadCompleted: boolean;
    batchId: string;
    batchName: string;
    archivedByUserName: string;
    organizerReminder: Date;
    signingReminder: Date;
    spouseSignerStatus: SignatureStatus;
    tpSignerStatus: SignatureStatus;
    locationId: number;
    locationName: string;
    count: number;
    engagementType: EngagementType;
    isUTELinked:boolean;
    extraDocumentCount: number;
    totalDocumentsCount: number;
    drlPercentage: number;
    uploadedDRLCount: number;
    requestedDRLCount: number;
};

export type DeliveredILinkedMergedDetails = {
    emailId: string;
    clientId: string;
    name: string;
    isPrimaryUTE: boolean;
    isAnyMergedEmailPresent: boolean;
};

export type DeliveredILinkedMergedDetailsResponse = {
    linkedDetails: DeliveredILinkedMergedDetails[];
    error: boolean;
};

export type DeliveredGatherData = {
    model: IGatherTableModel;
    query: string;
    page: number;
    pageSize: number;
}

export interface IDeliveredGatherStoreState {
    model: IGatherTableModel;
    loading: boolean;
    popupLoading: boolean;
    sourceDocumentMetaData: any;
    query: string;
    page: number;
    pageSize: number;
}
export interface DownloadableDocuments {
    fileName: string;
    downloadDate: Date | undefined;
    uploadedOn: Date | undefined;
    downloadBy: string;
    type: DownloadDocumentType;
    organizerDocumentRequestId: number | null;
    fileSize: number | null;
    id: number;
}

export interface IGatherDRLName {
    id: number;
    name: string;
    categoryName: string;
    type: string;
    notApplicable: boolean;
}

export interface IGatherDRLEntry {
    documentId: number;
    name: string;
}

export interface UpdateDRLMappingPayload {
    documentId: number;
    name: string;
    gatherDocumentRequestIds: number[];
    identifiers: number[];
}

export interface DRLMappingPayload {
    existingMapping : UpdateDRLMappingPayload[],
    newMapping : IGatherDRLEntry[] | null
}

export interface UpdateDRLNotApplicableStatusPayload {
    gatherDocumentRequestId: number | null;
    notApplicable: boolean;
}
export enum DownloadDocumentType {
    None = 0,
    Organizer = 1,
    EngagementLetter = 2,
    SourceDocument = 3
}

export type ReportProblemData = {
    issueId: string;
}

export interface ReportProblemDataStoreState {
    issueId: string;
    loading: boolean;
    popupLoading: boolean;
}


export interface IColumnSetting {
    id: number;
    key: string;
    columnName: string;
    isActive: boolean;
    isRequired?: boolean;
    isDraggable?: boolean;
}

export interface IColumnSettingState {
    reportColumnSettings: IColumnSetting[];
    isLoading: boolean;
}

export interface IFilterState {
    filterTaxpayerName: string;
    filterClientId: string;
    filterDeliveredOn?: Date;
    filterEngagementLetterStatus: string;
    filterOrganizerStatus: string;
    filterSourceDocumentStatus: string;
    filterOrganizerReminder?: Date;
    filterSigningReminder?: Date;
    filterTaxYear: string;
    filterSentBy: string;
    filterEro: string;
    filterLocation: string;
    filterBatchName: string;
    filterTaxpayerEmail: string;
    filterSpouseEmail: string;
    sortName: string;
    sortOrder: string;
    filterArchivedBy: string;
    filterDRLPercentage: string;
};
export const intialFilterState: IFilterState = {
    filterClientId: "",
    filterDeliveredOn: undefined,
    filterEngagementLetterStatus: "",
    filterOrganizerStatus: "",
    filterSourceDocumentStatus: "",
    filterOrganizerReminder: undefined,
    filterSigningReminder: undefined,
    filterSentBy: "",
    filterEro: "",
    filterLocation: "",
    filterTaxpayerName: "",
    filterTaxYear: "",
    filterBatchName: "",
    filterTaxpayerEmail: "",
    filterSpouseEmail: "",
    sortName: "deliveredOn",
    sortOrder: "desc",
    filterArchivedBy: "",
    filterDRLPercentage:""
};

export const initialFilter: IGatherFilter = {
    fields: {},
    name: "",
    searchText: "",
    sort: {
        column: "",
        direction: SortDirections.None
    },
    filterType: GatherFilterType.Delivered,
    isDefaultFilter: false
};


