import { FC, useEffect, useRef, useState } from "react";
import Layout, { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import AppRoute from "route";
import {
  getProductLeftMenuItems,
  getProductWidgetMenuItems,
  getRbacResourceList,
  getSuiteUrl
} from "store/services/layout-service";
import { getDefaultSettingData, getSafeSendUserGroups, requestUserProfile, saveDefaultSettingData } from "store/services/user-service";
import { SupportIcon } from "assets/svg/SVGIconsCollection";
import { requestIsTestCompany, requestCompanyLogo } from "store/services/company-service";
import {
  requestCommonSettings,
  requestCompanyOfficeLocations,
  requestCompanyProfile,
  requestCompanySignatureLink
} from "store/services/company-service";
import { getPartners, getSafeSendUsersNotInExchangeAndSignature, getUserOfficeLocations } from "store/services/user-service";
import MyDownload from "common/components/modal/my-download/MyDownload";
import { persistor, store } from "store";
import MySettings from "common/components/modal/my-settings/MySettings";
import { IUserDefaultSettingsStates, initalUserSettings } from "common/model/userSetting";
import { DefaultSenderInfoType, DownloadType } from "common/enum/GatherEnums";
import { AppState } from "store";
import { IUserDataState, IUserGroupViewModel } from "common/model/user";
import MyAccountModal from "common/components/modal/my-account/MyAccountModal";
import { requestDefinedPasswordPolicies } from "store/services/password-service";
import { IUploadTemplateState } from "common/model/upload-template";
import { getAllUploadTemplates } from "store/services/upload-template-service";
import { UserAutoLogoutModal } from "common/components/navigation/UserAutoLogoutModal";
import { MemoizedSignalRWebSocket } from "common/components/SignalR/SignalRWebSocket";
import { getGatherCompanySettingsModel } from "store/services/companySettings-service";
import { useHistory } from "react-router-dom";
import { ICompanyProduct } from "common/model/company";
import { getMyDownloads } from "store/services/my-download-service";
import { GatherCompanySettingsModel, ICompanyLogoSetting } from "common/model/company-settings";
import { getCookieValue, SessionTimeout, SessionTimeoutHelper } from "@sssuite-component-ui/session-timeout";
import { signoutRedirect } from "oidc-client/userService";
import LogoutModal from "common/components/modal/my-account/LogoutModal";

import { useForethought } from "common/hooks/forethought-hook";
import { setLoggedOut } from "store/slices/auth-slice";
import { RestrictedAccess } from "RestrictedAccess";
import { IDeliveredGatherStoreState } from "common/model/delivered-gather-table";
import { requestDeliveredGather } from "store/services/delivered-gather-service";
import MyLoginHistoryModal from "common/components/modal/my-account/MyLoginHistoryModal";
import LastLoginModal from "common/components/modal/my-account/LastLoginModal";
import { RestrictedAccessState } from "store/slices/restricted-access-slice";
import appInsights, { getDataForAppInsights } from "ai-logger/TelemetryService";
import { checkAndRequestNewData } from "helper/HelperFunctions";
import { ILocationData } from "common/model/GatherDocumentModel";
import { IPasswordPolicySettings } from "common/model/PasswordPolicySettings.model";
import Axios from "services/api/axios-interceptor";
import { getGatherTemplate } from "store/services/gathertemplate-settings-service";
import { resetUserCache } from "store/services/auth-service";
import { getCustomQuestions } from "store/services/custom-questions-service";
interface AppLayoutProps {
  children: any;
}
declare global {
  interface Window {
    Variables: any;
    pendo: any;
    Forethought: any;
  }
}
const WarningVisibleMinutes = 1;
const sessionTimeoutMinutes = Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES);

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const [sideBarData, setSideBarData] = useState<ISideMenuProps>();
  const [headerModel, setHeaderModel] = useState<any>();
  const [showMyDownload, setShowMyDownload] = useState(false);
  const [showMySettings, setShowMySettings] = useState(false);
  const [showTemplateSelectionModal, setShowTemplateSelectionModal] = useState<boolean>(false);
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showLoginHistory, setShowLoginHistory] = useState(false);
  const [showLastLogin, setShowLastLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const dispatch = useAppDispatch();
  const { isUserPrivilegeChanged } = useAppSelector((state: any) => state.userPrivilegeChangedReducer);
  const { leftMenuData, routeList }: any = useAppSelector((state: any) => state.layoutReducer);
  const { widgetMenu, suiteUrl }: any = useAppSelector((state: any) => state.layoutReducer?.widgetMenuData);
  const { safesendUsers }: IUserDataState = useAppSelector((state: AppState) => state.userReducer);
  const safesendUserGroups: IUserGroupViewModel[] = useAppSelector((state: AppState) => state.userReducer?.safesendUserGroups);
  const { partners }: IUserDataState = useAppSelector((state: AppState) => state.userReducer);
  const { loggedInUserProfile }: IUserDataState = useAppSelector((state: AppState) => state.userReducer);
  const { templates }: IUploadTemplateState = useAppSelector((state: AppState) => state.uploadTemplateReducer);
  
  const [getDefaultSettings, setDefaultSettings] = useState(initalUserSettings);
  const getMyDefaultSettings: IUserDefaultSettingsStates = useAppSelector((state: AppState) => state.userSettingReducer);
  const companyProducts: ICompanyProduct[] = useAppSelector((state: AppState) => state.companyReducer.companyProducts);
  const officeLocations: ICompanyProduct[] = useAppSelector((state: AppState) => state.companyReducer.officeLocations);
  const taxSoftwareSetting: ICompanyProduct[] = useAppSelector((state: AppState) => state.companyReducer.taxSoftwareSetting);
  const companyLogo: ICompanyLogoSetting = useAppSelector((state: AppState) => state.companyReducer.companyLogoSetting);
  const signatureUploadLink: ICompanyLogoSetting = useAppSelector((state: AppState) => state.companyReducer.signatureUploadLink);
  const companyName: string = useAppSelector((state: AppState) => state.companyReducer.companyInfo.companyName);
  const companySubscription: string = useAppSelector((state: AppState) => state.companyReducer.companySubscription.name);
  const isAllRoutesDisabled = useAppSelector((state: AppState) => state.layoutReducer.isAllRoutesDisabled);
  const { authentication, isLoggedOut } = useAppSelector((state: AppState) => state.authReducer);
  const deliveredGatherData: IDeliveredGatherStoreState = useAppSelector((state: AppState) => state.deliveredGatherReducer);
  const restrictedAccessData: RestrictedAccessState = useAppSelector(((state: AppState) => state.restrictedAccessReducer));
  const [restrictedAccessPath, setRestrictedAccessPath] = useState<string>(restrictedAccessData?.routePath);
  const isTestCompany: boolean = useAppSelector((state: AppState) => state.companyReducer?.isTestCompany);
  const rbacList: any = useAppSelector((state: AppState) => state.layoutReducer?.rbacData?.resourceList);
  const { locations }: ILocationData = useAppSelector((state: AppState) => state.userLocationReducer);
  const { passwordPolicy }: IPasswordPolicySettings = useAppSelector((state: AppState) => state.passwordPolicyReducer);
  const companySettings: GatherCompanySettingsModel = useAppSelector((state: AppState) => state.companySetting);

  const [forethoughtClick] = useForethought();

  const isCompanyGatherSubscriptionEnabled = companyProducts.some(
    (x) => x.productId == ProductType.Gather && x.isSubscribed == true
  );
  const isCompanyGatherProductEnabled = companyProducts.some(
    (x) => x.productId == ProductType.Gather && x.isSubscribed == true && x.productStatus == 1
  );

  const hasShownModal = useRef(false);

  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem("userAutoLoggedout") === "1") {
      localStorage.removeItem("userAutoLoggedout");
      signoutRedirect();
    } 
    else {
    dispatch(setLoggedOut(false));
    dispatch(requestCompanyProfile());
    dispatch(requestCompanyLogo());
    dispatch(getCustomQuestions());
    dispatch(getDefaultSettingData());  
    checkAndRequestNewData(loggedInUserProfile?.emailAddress, dispatch,requestUserProfile);
    checkAndRequestNewData(Array.isArray(leftMenuData) ? leftMenuData : leftMenuData.leftMenuData, dispatch, getProductLeftMenuItems);
    checkAndRequestNewData(widgetMenu, dispatch, getProductWidgetMenuItems);
    checkAndRequestNewData(isTestCompany, dispatch, requestIsTestCompany);
    checkAndRequestNewData(rbacList, dispatch, getRbacResourceList);
    checkAndRequestNewData(partners, dispatch, getPartners);
    checkAndRequestNewData(safesendUserGroups, dispatch, getSafeSendUserGroups);
    checkAndRequestNewData(passwordPolicy, dispatch, requestDefinedPasswordPolicies);
    checkAndRequestNewData(safesendUsers, dispatch, getSafeSendUsersNotInExchangeAndSignature);
    checkAndRequestNewData(templates, dispatch, getGatherTemplate);

    if (appInsights.config?.instrumentationKey?.length === 0) {
      getDataForAppInsights(() => {
        appInsights.trackPageView({ name: 'HomePage' });
      });
    }
    else {
      appInsights.trackPageView({ name: 'HomePage' });
    }

    if (getCookieValue("userAutoLogout") !== "1") {
      resetUserCache();
    }
  }
  }, []);

  useEffect(()=>{
    dispatch(getSuiteUrl());
  }, [isCompanyGatherProductEnabled]);

  useEffect(() => {
    injectPendoScript();
  }, [isTestCompany, companySubscription])

  useEffect(() => {
    if (isCompanyGatherProductEnabled) {
      checkAndRequestNewData(signatureUploadLink, dispatch, requestCompanySignatureLink);
      checkAndRequestNewData(officeLocations, dispatch, requestCompanyOfficeLocations);
      checkAndRequestNewData(getMyDefaultSettings?.defaultUserSettings, dispatch, getDefaultSettingData);
      checkAndRequestNewData(taxSoftwareSetting?.taxSoftware, dispatch, requestCommonSettings);
      checkAndRequestNewData(locations, dispatch, getUserOfficeLocations);
      checkAndRequestNewData(companySettings?.defaultSettings?.initialEmail, dispatch, getGatherCompanySettingsModel);
    }
  }, [isCompanyGatherProductEnabled]);

  useEffect(() => {
    getDataForSideMenu(
      leftMenuData,
      isAllRoutesDisabled || !isCompanyGatherSubscriptionEnabled || !isCompanyGatherProductEnabled
    );
    redirect(routeList);
  }, [leftMenuData, routeList, isAllRoutesDisabled, isCompanyGatherSubscriptionEnabled, isCompanyGatherProductEnabled]);

  useEffect(() => {
    setHeaderModel(headerConfig(companyName, loggedInUserProfile.firstName, widgetMenu, suiteUrl, companyLogo));
  }, [widgetMenu, suiteUrl, companyLogo, companyName]);

  useEffect(() => {
    setRestrictedAccessPath(restrictedAccessData?.routePath);
  }, [restrictedAccessData])

  const getDataForSideMenu = (menuSections: IMenuSection[], hideLeftMenu: boolean) => {
    const sideMenuProps: ISideMenuProps = {
      hasButton: false,
      topMenuData: [],
      bottomMenuData: [],
      onNavigate: (route: string) => {
        history.push(route);
      },
      hideSideMenu: hideLeftMenu,
      buttonIcon: "",
      buttonText: "",
      onButtonClick: () => { }
    };

    if (menuSections && menuSections.length) {
      let topMenuData = menuSections.filter((x) => x.items.some((y) => !y.isAtBottomOfLeftMenu));
      sideMenuProps.hasButton = false;
      sideMenuProps.bottomMenuData = menuSections.filter((x) => x.items.some((y) => y.isAtBottomOfLeftMenu));
      sideMenuProps.topMenuData = topMenuData.filter(
        (section) => !(section.items.length === 1 && section.items[0].caption === "Settings" && section.items[0].disabled)
      );
    }
    setSideBarData(sideMenuProps);
  };
  const redirect = (routes: GatherRoute[]) => {
    const path = AppRoute.history.location.pathname;
    if (path === "/" || path === "/OrganizerReport") {
      const foundRoute = routes?.find((r) => r.isEnabled);
      foundRoute && history.push(foundRoute.route);
      appInsights.trackPageView({ pageType: "Side Menu", name: foundRoute?.route });
    }
  };
  const getSupportIcon = () => {
    return (
      <div onClick={forethoughtClick} className="col-md-3 bootstrap-grid-no-padding">
        <SupportIcon title="Support" className="cursor-pointer" />
      </div>
    );
  };

  const headerConfig = (
    companyName: string,
    firstName: string,
    widgetMenus: ISectionData[],
    suiteUrl: string,
    companyLogoSetting: ICompanyLogoSetting
  ): IHeaderProps => {
    let headerprops: IHeaderProps = {
      productName: ProductType.Gather,
      onClickProductLogo: () => {
        AppRoute.redirect("/");
      },
      children: getSupportIcon(),
      profileDropDown: {
        dropdownHeader: `Hi, ${firstName?.trim()}!`,
        dropdownHeaderTitle: `${firstName?.trim()}`,
        onClickLogout: () => { setShowLogout(true) },
        profileDropDownItems: [
          {
            caption: "Profile",
            onClick: () => {
              setShowMyAccount(true);
            }
          },
          {
            caption: "My Settings",
            onClick: () => {
              setShowMySettings(true);
            }
          },
          {
            caption: "My Downloads",
            onClick: () => {
              setShowMyDownload(true);
            }
          },
          {
            caption: "Login History",
            onClick: () => {
              setShowLoginHistory(true);
            }
          }
        ]
      },
      widgetData: {
        onBackClick: () => {
          window.open(suiteUrl, "_blank");
        },
        dropDownData: widgetMenus
      }
    };
    if (companyLogoSetting.isSsrLogo) {
      headerprops.companyName = companyName;
    } else {
      headerprops.companyLogo = companyLogoSetting.logoPath;
    }
    return headerprops;
  };
  const onMyDownloadToggle = () => {
    setShowMyDownload(!showMyDownload);
  };

  const getCompanyId = () => {
    return store.getState()?.authReducer?.authentication?.user.profile.company_id;
  };

  const getUserId = () => {
    return store.getState()?.authReducer?.authentication?.user.profile.user_id;
  };
  var injectPendoScript = () => {
    const {
      company_id,
      company_name,
      user_id,
      given_name: firstName,
      family_name: lastName,
      email
    } = store.getState()?.authReducer?.authentication?.user.profile;

    if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
      console.log("User profile not available yet");
      return;
    }
    const apiKey = process.env.REACT_APP_PENDO_API_KEY;

    (function (p: any, e: any, n: any, d: any, o: any) {
      var v: any, w: any, x: any, y: any, z: any;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo", "");

    window.pendo?.initialize({
      visitor: {
        id: `${company_id}-${user_id}-${email}`,
        email: email,
        firstName: firstName,
        lastName: lastName
      },
        account: {
        accountid: company_id,
        accountName: company_name,
        firmType: isTestCompany ? "Internal" : "Live",
        subscription: companySubscription
      }
    });
  };
  const onMySettingsToggle = () => {
    setShowMySettings(!showMySettings);
  };

  const onMyLoginHistoryToggle = () => {
    setShowLoginHistory(!showLoginHistory);
  };

  //My Settings
  const onChangeEROStamp = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.eroUser = event;
    setDefaultSettings(newSettings);
  };

  const onChangeContactPerson = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.contactPerson = event;
    setDefaultSettings(newSettings);
  };

  const onChangeNotifySigningEvents = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.notifyELEventsUser = event;
    setDefaultSettings(newSettings);
  };

  const onChangeNotifyOrganizerEvents = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.notifyOrganizerEventsUser = event;
    setDefaultSettings(newSettings);
  };

  const onChangeNotifySourceDocEvents = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.notifySourceDocEventsUser = event;
    setDefaultSettings(newSettings);
  };

  const onChangeDefaultDownload = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.download = {
      downloadType: event,
      rememberDefaultDownload: event == DownloadType.PromptMeEveryTime ? false : true
    };
    setDefaultSettings(newSettings);
  };

  const onChangeGatherTemplate = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.gatherTemplate = event;
    setDefaultSettings(newSettings);
  };

  const onChangeSenderName = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.sender = {
      senderId: event,
      senderType: DefaultSenderInfoType.User
    };
    setDefaultSettings(newSettings);
  };

  const onSubmitClick = () => {
    var userId = loggedInUserProfile.userId;
    dispatch(
      saveDefaultSettingData(userId, getDefaultSettings, () => {
        onCancel();
      })
    );
  };

  useEffect(() => {
    if (getMyDefaultSettings.defaultUserSettings) {
      setDefaultSettings(getMyDefaultSettings.defaultUserSettings);
    }
    if (
      getMyDefaultSettings?.defaultUserSettings?.enableLastLogin === true &&
      !hasShownModal.current
    ) {
      setShowLastLogin(true);
      hasShownModal.current = true;
    }
  }, [getMyDefaultSettings.defaultUserSettings]);

  const onMyAccountToggle = () => {
    setShowMyAccount(!showMyAccount);
  };
  const onLogoutToggle = () => {
    setShowLogout(!showLogout);
  }

  const handleCompanySelect = (event: any) => {
    let newSettings = { ...getDefaultSettings };
    newSettings.sender = {
      senderId: getDefaultSettings.sender.senderId,
      senderType:
        event.currentTarget.defaultValue == DefaultSenderInfoType.User
          ? DefaultSenderInfoType.User
          : DefaultSenderInfoType.Company
    };
    setDefaultSettings(newSettings);
  };
  const onCancel = () => {
    setShowMySettings(false);
    dispatch(getDefaultSettingData());
  };

  const getMyDownloadList = () => {

    dispatch(getMyDownloads(() => {
      const deliveredGatherReportData: IDeliveredGatherStoreState = store.getState().deliveredGatherReducer;
      if (deliveredGatherReportData)
        dispatch(requestDeliveredGather(
          deliveredGatherReportData.query,
          deliveredGatherReportData.page,
          deliveredGatherReportData.pageSize));
    })
    );
  };

  const handleLogout = () => {
    SessionTimeoutHelper.logoutHelper(authentication?.user?.profile.device_id, signoutRedirect);
    Axios.cache.clear();
    if (persistor) {
      persistor.purge();
    }
    dispatch(setLoggedOut(true));
  }

  return (
    restrictedAccessPath === AppRoute.RESTRICTED_ACCESS ? (
      <RestrictedAccess />
    ) :
      <>
        {headerModel ? (
          <Layout headerProps={headerModel} sideMenuProps={sideBarData}>
            <main className="content-wrapper">{children}</main>
          </Layout>
        ) : (
          <></>
        )}
        <MyDownload show={showMyDownload} onHide={onMyDownloadToggle} onSubmit={onMyDownloadToggle} />
        <MySettings
          show={showMySettings}
          onHide={onMySettingsToggle}
          onSubmit={onMySettingsToggle}
          defaultSettings={getDefaultSettings}
          onChangeDefaultDownload={onChangeDefaultDownload}
          users={safesendUsers}
          userGroups={safesendUserGroups}
          ero={partners}
          onChangeEROStamp={onChangeEROStamp}
          onChangeContactPerson={onChangeContactPerson}
          onChangeNotifyOrganizerEvents={onChangeNotifyOrganizerEvents}
          onChangeNotifySigningEvents={onChangeNotifySigningEvents}
          onChangeNotifySourceDocEvents={onChangeNotifySourceDocEvents}
          onChangeSenderName={onChangeSenderName}
          onChangeGatherTemplate={onChangeGatherTemplate}
          onSave={onSubmitClick}
          templateList={templates}
          onhandleCompanySelect={handleCompanySelect}
          onCancel={onCancel}
        />
        <MyAccountModal show={showMyAccount} onHide={onMyAccountToggle} />
        <MyLoginHistoryModal show={showLoginHistory} onHide={onMyLoginHistoryToggle} />
        <MemoizedSignalRWebSocket company_id={getCompanyId()} user_id={getUserId()} getMyDownloadList={() => getMyDownloadList()} />
        <UserAutoLogoutModal openModal={isUserPrivilegeChanged} />
        <LogoutModal show={showLogout}
          onHide={onLogoutToggle} onLogout={handleLogout} />
        <SessionTimeout
          deviceId={authentication?.user?.profile.device_id}
          logout={handleLogout}
          sessionIdleMinutes={sessionTimeoutMinutes}
          warningVisibleMinutes={WarningVisibleMinutes}
          currentTabLoggedOutShow={isLoggedOut}
          setCurrentTabLoggedOutShow={() => {
            dispatch(setLoggedOut(true));
          }}
        />
        <LastLoginModal show={showLastLogin} onHide={() => setShowLastLogin(false)} defaultSettings={getMyDefaultSettings.defaultUserSettings} />
      </>
  );
};
export default AppLayout;
