import Loader from "common/components/loader/Loader";
import { useAppSelector } from "common/hooks/redux-hooks";
import { PASSWORD_PROTECTION_CHECK_URL } from "helper/Constants";
import { FC, useEffect, useState } from "react";
import { AppState } from "store";

interface LoaderWrapperProps {
    children?: React.ReactNode;
    keys?: string[];
    loadingText?: string;
    spinnerStyle?: string;
    labelStyle?: string;
    labelVisibilty?: boolean;
    isScrollableView?: boolean;
    backgroundVisibility?: boolean;
    overLayClassName?: string;
}
const DEFAULT: string = 'default';
/** Wrapper component, which can handle adding/removing Loader when an API request is made ,
 * You can add this wrapper wherever you need to show the loader on the wrapped component/element.
 * @param keys Array of keys (based on the API request, ideally the endpoint) to which the loader should be displayed.
 * @param loadingText Status text which will get displayed under spinner.
 * @param spinnerStyle CSS classname to customize spinner.
 * @param labelStyle CSS classname to customize status text.
 * @param labelVisibilty Flag to make status label shown/hidden, Default value is true
 * @param isScrollableView Flag to make Loader as fixed when the page scrolls label
 * @param backgroundVisibility Flag to show/hide white background, Default is false
*/
const LoaderWrapper: FC<LoaderWrapperProps> = ({
    children,
    keys,
    loadingText,
    spinnerStyle,
    labelStyle,
    labelVisibilty,
    isScrollableView,
    backgroundVisibility,
    overLayClassName
}) => {
    const loadingStatus = useAppSelector((state: AppState) => state?.loadingStatusReducer);
    const [showLoader, setShowLoader] = useState<boolean>(false);


    useEffect(() => {

        const currentKey =
            Array.isArray(keys) && keys.length > 0
                ? keys.find(key => loadingStatus[key])
                : Object.keys(loadingStatus).find(
                    key => key.includes(PASSWORD_PROTECTION_CHECK_URL) && loadingStatus[key]
                ) || DEFAULT;

        setShowLoader(loadingStatus[currentKey]);
    }, [loadingStatus]);

    return <>
        {
            isScrollableView ? <div className="scrollable-loader-wrapper">
                {children}
                {
                    showLoader && <Loader label={loadingText}
                        overLayClassName={overLayClassName}
                        spinnerClassName={spinnerStyle}
                        labelClassName={labelStyle}
                        labelVisibilty={labelVisibilty}
                        isScrollableView={isScrollableView}
                        backgroundVisibility={backgroundVisibility} />
                }
            </div> : <>
                {children}
                {
                    showLoader && <Loader label={loadingText}
                        spinnerClassName={spinnerStyle}
                        labelClassName={labelStyle}
                        labelVisibilty={labelVisibilty}

                        backgroundVisibility={backgroundVisibility} />
                }
            </>}
    </>
}
export default LoaderWrapper;