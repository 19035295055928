import { LogoutCause } from "common/enum";
import { signoutRedirect } from "oidc-client/userService";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { setUserPrivilegeChangedModel } from "store/slices/user-previlege-changed-slice";
import { UserAutoLogoutModalConstants } from "helper/Constants";
import "./AutoLogout.scss";
import { CustomModal } from "../UIComponents/CustomModal";
import { resetUserCache } from "store/services/auth-service";
import { useEffect } from "react";

interface UserAutoLogoutModalProps {
  openModal: boolean;
}

export const UserAutoLogoutModal: React.FC<UserAutoLogoutModalProps> = (props) => {
  var dispatch = useAppDispatch();

  useEffect(() => {
    if (props.openModal) {
      localStorage.setItem("userAutoLoggedout", "1");
    }
  }, [props.openModal]);

  const onCloseModal = async () => {
    localStorage.removeItem("userAutoLoggedout");
    await resetUserCache();
    dispatch(setUserPrivilegeChangedModel({ logoutCause: LogoutCause.None, isUserPrivilegeChanged: false }));
    signoutRedirect();
  };

  return (
    <CustomModal
      title={UserAutoLogoutModalConstants.PermissionChanged}
      show={props.openModal}
      hideCancelButton={true}
      confirmButtonName={"Login"}
      onHide={onCloseModal}
      onSubmit={onCloseModal}
      isConfirmModal={true}
    >
      <p>{UserAutoLogoutModalConstants.LogoutModalMessage}</p>
    </CustomModal>
  );
};
