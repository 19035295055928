export const GeneralSettingConstants = {
  disableEngagementLetterPopoverMessage: "Applicable only for TaxSoftwares GoSystem, UltraTax and Lacerte",
  engagementLetterHeading: "Engagement Letter Default",
  engagementLetterCheckbox: "Allow users to deliver organizers without Engagement Letters",
  sourceDocumentHeading: "Gather Source Document",
  sourceDocumentChecbox: "Enable gathering for taxpayer source documents",
  SourceDocumentNotificationDefault:
    "Please note the Source Document Notification option has been reset to the default, review and change if needed.",
  sourceDocumentNotificationHeading: "Source Document Notification",
  notifyEveryTimeUploadOrDeleteSrcDocRadioBtn: "Send notification every time client Uploads/Deletes a source document",
  schNotification1PerDayUploadOrDeleteSrcDocRadioBtn:
    "Send scheduled notification once per day, if client Uploaded/Deleted source document(s)",
  sourceDocumentUploadNotificationDisabledRadioBtn: "Source Document Upload Notification Disabled",
  sourceDocNotificationtoolTip: "Enable gather source document to configure email notification.",
  automaticRemaindersHeading: "Automatic Reminders",
  engagementType: "Engagement Type",
  automaticRemaindersCheckbox: "Enable Automatic Reminders",
  promptMessage: "Any changes made to settings will be lost on navigating without saving. Do you wish to continue?",
  sendReminderSigningMsg: "Send a Reminder every",
  sendReminderSigningMsgTrail: "since last reminder/initial email",
  completionNotifications: "Set Completion Notifications",
  uploadNotificationLable: "Document Upload Completion Notification",
  uploadDocumentPercentageMsg: "Send a Notification when",
  uploadDocumentPercentageMsgTrail: "of requested source documents is reached.",
  UploadK1PercentageMsg: "Send a Notification when",
  UploadK1PercentageMsgTrail: "of requested K-1 documents is reached."
};
export enum SourceDocumentNotification {
  NotifyEveryTimeUploadOrDeleteSrcDoc = 1,
  SchNotification1PerDayUploadOrDeleteSrcDoc = 2,
  NoAdditionalCPANotificationRequired = 3
}

export const AddEditModalDialog = {
  CLIENT_INSTRUCTION: {
    EDIT_TITLE: "Edit Client Instructions",
    ADD_TITLE: "Add Client Instructions",
    ADD_BUTTON_TEXT: "Save",
    UPDATE_BUTTON_TEXT: "Update",
    NAME_VALIDATION_TEXT: "Please enter instruction name.",
    SUBJECT_VALIDATION_TEXT: "Please enter instruction subject.",
    BODY_VALIDATION_TEXT: "Please enter instruction text.",
    NAME_PLACEHOLDER_TEXT: "Instruction name",
    SUBJECT_PLACEHOLDER_TEXT: "Email Subject",
    SYSTEM_DEFAULT_TEXT: "System Default",
    BODY_PLACEHOLDER_TEXT: "Please enter instruction text",
    SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT: "System default template cannot be modified",
    DUPLICATE_INSTRUCTION_NAME: "Name already exists",
    DELETE_DEFAULT_INSTRUCTION_WARNING: "You cannot delete the default client instruction.",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    ValidateName: "Please enter a message name.",
    ValidateBody: "Please enter a message body.",
    TextSizeExceedsLimit: "Your email content exceeds 45,000 bytes, preventing save. Please make necessary changes to proceed.",
    MaximumTextSize: 45000
  },
  ENGAGEMENT_TYPE: {
    EDIT_TITLE: "Edit Engagement Type",
    ADD_TITLE: "Add Engagement Type",
    NAME_ALAPANUMERIC_VALIDATION: "Engagement type must be alphanumeric.",
    SYSTEM_DEFAULT_HOVER_TEXT: "System default cannot be modified",
    NAME_VALIDATION_TEXT: "Please enter engagement type.",
    CATEGORY_VALIDATION_TEXT: "Please select engagement category.",
    DUPLICATE_INSTRUCTION_NAME: "Engagement type already exists.",
    LIMIT_EXCEEDED: "Maximum limit for custom engagement type has reached.",
    NAME_PLACEHOLDER_TEXT: "Engagement type",
    INFO_MESSAGE: "Previously delivered documents will not be affected by the change in the setting.",
    CATEGORY_INFO_TEXT: "The engagement category will dictate key flows with the Gather request. Please select the best option for the engagement you are sending. For more information consult our Help Center."
  },
  TP_MESSAGE: {
    ADD_TITLE: "Add Message from Tax Preparer",
    EDIT_TITLE: "Edit Message from Tax Preparer",
    NAME_PLACEHOLDER_TEXT: "Message name"
  }
};
export const ClientInstructionMessageVariables = {
  CompanyName: "The listed Name of your company on your account",
  TaxPayerName: "The listed Taxpayer on the Gather request",
  SpouseName: "The listed Spouse on the Gather request",
  HelpContact: "The name of the user designated for customer support",
  HelpPhone: "The phone number of the user designated for customer support",
  HelpEmail: "The email address of the designated help contact",
  Recipient: "The person name for which the email is being sent",
  TaxYear: "The Tax year on the Gather request",
  SenderName: "The name of the user selected as the 'Sender' of the Gather request"
};
export const ReportFilterConstants = {
  StatusMessage: {
    SavedSuccessMessage: "Filter saved successfully.",
    SavedErrorMessage: "Failed to save the filter!",
    UpdateSuccessMessage: "Filter updated successfully.",
    UpdateErrorMessage: "Failed to update the filter!",
    DeleteSuccessMessage: "Filter deleted successfully.",
    DeleteErrorMessage: "Failed to delete the filter!",
    GetAllErrorMessage: "Failed to fetch the filters!",
    RemoveDefaultFilterSuccess: "Default filter has been removed successfully.",
    DefaultFilterSuccess: "Default filter has been updated successfully."
  },
  ValidationMessage: {
    NoRecentFilters: "There is no recent filter to apply!"
  },
  ControlsLabel: {
    ApplyRecentFilterBtn: "Apply recent filter",
    SaveCurrentFilterBtn: "Save current filter",
    SetAsDefaultFilter: "Set as Default Filter",
    DeleteFilter: "Delete Filter",
    ExportToExcel: "Export to Excel",
    ClearFilter: "Clear Filter",
    RemoveDefault: "Remove Default",
    PleaseEnterTheFilterName: "Please enter the filter name",
    Apply: "Apply",
    SetDefault: "Set Default",
    Delete: "Delete",
    AppliedFilter: "Applied Filter",
    Refresh: "Refresh"
  },
  OtherMessage: {
    DeleteFilter: "Are you sure you want to delete this filter?"
  },
  ControlsTitle: {
    ApplyMostRecentFilter: "Apply Most Recent Filter",
    ToggleDropdownMenu: "Toggle dropdown menu"
  },
  DefaultFilterBuilder: "_D_FILTER", //This suffix is trimmed to maintain total length to 25,
  MaxLength: 100
};

export const CommonConstants = {
  Required: "Required"
};

export const NO_DATA_TEXT = "No records found";
export const NO_ERROR_TEXT = "No errors";
export const BATCH_PREVIEW_NO_DATA_TEXT = "There is no data to display";

export const PAGESIZE = 20;
export const EXPANDED_PAGESIZE = 10;
export const MAX_FILE_TEXT_LENGTH = 35;

export const DeleteModalConstants = {
  InProgress: {
    title: "Delete Gather",
    message: "You are about to delete the selected Gather request(s). Do you want to proceed?",
    expandedDelete: "You are about to delete the selected Gather request from the uploaded batch. Do you want to proceed?"
  },
  Delivered: {
    title: "Delete Gather",
    message: "You are about to delete the selected Gather request(s). Do you want to proceed?",
    note: "Any scheduled reminders will be turned off for recycled Gather request(s)."
  },
  Undelivered: {
    title: "Confirm Gather Batch Delete",
    message: "You are about to delete the selected batch. This cannot be undone. Do you want to proceed?"
  },
  RecycleBin: {
    title: "Permanent Delete",
    message: "Are you sure you want to delete the selected Gather request(s)?",
    additionalMessage:
      "On Delete, System will permanently delete all the Gather request(s) from SafeSend Gather and they will be no longer available to anyone and the Taxpayer's access link will be disabled permanently."
  },
  SavedMessage: {
    title: "Delete Message",
    message: "Are you sure you want to delete this message?"
  },
  EngagementType: {
    title: "Engagement Type Deletion",
    continueMessage: " Do you want to continue?",
    message: "Deleting this engagement type will prevent it from being used in future deliveries. All delivered and in-process Gather requests with the deleted engagement type will still have it."
  },
  SendOrganizer: {
    title: "Delete File",
    message: "Are you sure you want to delete this file?"
  }
};
export const RecycleBinConstants = {
  message: "Are you sure you want to restore the selected Gather request(s)?",
  note:
    "Any previously scheduled reminders will be turned on for Gather request(s) restored to delivered report."
};
export const TemplateModalConstants = {
  //Templates list
  RecentQuestionnaires: "Recent Questionnaires",
  StartFromScratch: "Start from scratch",
  BuildNewQuestionnaire: "Build a new questionnaire",
  WithYourCustomQuestions: "with your custom questions.",
  CreateNew: "Create New",
  // Edit Template
  UntitledQuestionnaire: "Title here",
  QuestionnaireDescription: "Brief description here",
  AddNewSection: "Add New Section",
  //Add Template Modal
  AddTemplateModalHeader: "Create New Template",
  EditTemplateModalHeader: "Rename Template",
  TemplateNamePlaceholder: "Enter template name",
  TemplateNameValidationMessage: "Please enter template name",
  TemplateDesciptionPlaceholder: "Enter template description",
  DeleteDefaultTemplateConfirmation: "This template is set to default , do you still want to delete this template ?",
  DeleteTitle: "Permanent Delete",
  DeleteConfirmation: "Are you sure want to delete the template ?",
  DeleteSuccessMessage: "Template deleted successfully", //TO confirm
  CustomTemplate: "Custom Template",
  DeleteTemplateInUse: "Template In-use",
  DeleteConfirmationForTemplateInUse:
    "You are trying to delete a questionnaire template that is being used for an organizer in process. Please confirm if you wish to permanently delete the selected questionnaire template.",
  DeleteConfirmationNote: "Uploaded Gather request(s) will retain this saved questionnaire until updated manually.",
  // Add Questionaire Modal

  AddModalHeader: "Create New Questionnaire",
  EditModalHeader: "Rename Questionnaire",
  AddModalConfirmButton: "Add",
  EditModalConfirmButton: "Save",
  NameLabel: "Name",
  NamePlaceholder: "Enter questionnaire name",
  NameValidationMessage: "Please enter questionnaire name",
  DescriptionValidationMessage: "Please enter questionnaire description",
  TemplateNameMaxLength: 100,
  TemplateDescriptionMaxLength: 100,
  NameMaxLength: 150,
  DesciptionLabel: "Description",
  DesciptionPlaceholder: "Enter questionnaire description",
  DesciptionMaxLength: 500,
  EditSuccessMessage: "Your changes are saved successfully", //TO confirm
  AddSuccessMessage: "Template added successfully", //TO confirm
  SetDefaultTemplateSuccess: "Default template set successfully",
  UnsetDefaultTemplateSuccess: "Default template selection removed successfully",

  //Add Section Modal
  AddSectionModalHeader: "Create New Section",
  EditSectionModalHeader: "Rename Section",
  MergeSectionModalHeader: "Merge Section",
  ReorderSections: "Reorder Sections",
  AddSectionModalConfirmButton: "Continue",
  EditSectionModalConfirmButton: "Save",
  SectionMerge: "Merge",
  SectionNameLabel: "Name",
  SectionNamePlaceholder: "Enter section name",
  SectionNameValidationMessage: "Please enter section name",
  SectionNameMaxLength: 100,

  GatherTemplate: {
    addTemplate: "Create New Template",
    placeholder_description: "Add description",
    editTemplate: "Edit Template",
    taskOptionsLabel: "Task Options",
    cqLabel: "Ask Custom Questions",
    fillableOrg: "Send Fillable Organizer",
    requestDoc: "Request Document(s)",
    signDocs: "Sign Document(s)",
    inputMaxLength: 100
  }
};

export const FormPreviewConstants = {
  RequiredValidation: "This field is required",
  ParagraphTypeQuestion: {
    placeholder: "Answer goes here",
    maxLength: 3250
  },
  MultiCoiceTypeQuestion: {
    optionAlreadyExists: "Option already exists"
  }
};

export const CustomQuestionsTabConstants = {
  ChooseTemplate: "Choose Template",
  SelectTemplate: "Select Template",
  NoTemplate: "No Template Selected",
  NoQuestionnaiers: "No questionnaire yet?",
  GetStarted: "Let’s get started!",
  DeleteQuestionTitle: "Delete question and section",
  DeleteSectionTitle: "Section contains questions",
  DeleteQuestionConfrimation:
    "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
  DeleteSectionConfirmation:
    "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
  AtleastOneSectionRequired: "Template requires at least one section",
  AtleastOneQuestionRequired: "Section requires at least one question"
};

export const FormBuilderConstants = {
  QuestionTitle: {
    placeholder: "Enter Question here",
    maxLength: 2000,
    answerPlaceholder: "Answer goes here"
  },
  SAVE_DISABLED_TOOLTIP: "No content to save",
  PREVIEW_DISABLED_TOOLTIP: "No content to preview",
  DELETE_QUESTION_DISABLED_TOOLTIP: "Section requires at least one question.",
  DUPLICATE_SECTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected questionnaire.",
  DUPLICATE_QUESTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected question.",
  FORM_VALIDATION_ERROR: "Please check the error(s)",
  DELETE_MODAL_HEADER: "Confirm delete section",
  DELETE_MODAL_BODY: "Deleting the section will remove all the questions created under this section. Do you wish to continue?",
  MultiChoiceConstants: {
    placeholder: "Enter Option Here",
    maxLength: 300,
    optionsMaxLimit: 5,
    atLeastOneOptionText: "Question requires at least one option"
  }
};
export const DeliveredActionConstants = {
  Archive: {
    title: "Archive Gather",
    message: "You are about to archive the selected Gather Request(s). Do you want to proceed?",
    note: "Any scheduled reminders will be turned off for archived Gather request(s)."
  },
  ReOpenGather: {
    title: "Confirm Reopen Gather Request Access",
    message:
      "This Gather request has been marked as complete by the taxpayer/spouse. Do you wish to reopen the Gather request for the taxpayer/spouse?",
    note: "Automatic Reminders will not be turned on upon reopening. However, you can manually turn it on using the send reminder option in the delivered report screen."
  },
  ReOpenGatherByFirm: {
    title: "Confirm Open Gather Request",
    message:
      "The Gather request(s) will be open. The recipient(s) will be able to access the same Gather request link sent before. Reminders will be turned back on."
  },
  ReOpenDocUpload: {
    title: "Confirm Reopen Source Document Upload",
    message: "The source document upload is completed for this gather. Do you wish to reopen the Source Document upload?"
  },
  CloseOpen: {
    title: "Close/Open Gather request",
    message:
      "All closed Gather requests selected will reopen to allow taxpayer access. All open Gather requests selected will close to restrict taxpayer access. Do you wish to proceed?"
  },
  CloseByFirm: {
    title: "Confirm Close Gather Request",
    message:
      "The Gather request(s) will be closed. The recipient will no longer be able to access the Gather via the access links. All reminders will be stopped for this request. You can reopen at anytime to resume access and reminders."
  },
  SendReminder: {
    title: "Send Reminder",
    tab1: {
      title: "Send Reminder Now",
      ELText: "Engagement letter reminder",
      OrgText: "Gather-reminder ",
      SourceDocText: "Source document reminder"
    },
    tab2: {
      title: "Schedule Automated Reminder",
      sendReminderText1: "Send a Reminder every",
      sendReminderText2: "since last reminder / initial mail"
    }
  },
  DownloadHeader: {
    title: "Unable to Download",
    text1: "The selected gather request(s) in the following statuses cannot be downloaded:",
    signature: "Signature: Awaiting Signature",
    org: "Gather: Delivered",
    sourceDoc: "Source Document: Awaiting Upload"
  },
  Restore: {
    title: "Restore Gather Request",
    message: "Are you sure you want to restore the selected Gather request(s)?",
    note: "Any previously scheduled reminders will be turned on for restored request(s)."
  }
};
export const UserManagementConstants = {
  PleaseNoteText: "Please Note!",
  SelectUserGroupWarning: "Please select atleast one user role in Memberships.",
  LoadingUserError: "An Error occured when loading users.Please Try Again.",
  SavingUserError: "Saving user details failed, please re-login",
  UserUpdationError: "Updating user details failed, please re-login",
  EditUserLoadingError: "User Loading failed, please re-login.",
  DeletingUserError: "Deleting user failed, please re-login",
  SelectUserWarning: "Please select a user from the list.",
  SelectGroupWarning: "Please select a group from the list.",
  SaveUserSuccess: "User added successfully",
  DeleteUserSuccess: "Selected user has been deleted",
  UserUpdationSuccess: "User details has been updated.",
  DeleteUserConfirmation: "Are you sure you want to delete this user?",
  AddUserDetails: "Add User Details",
  SetPAssword: "Set Password",
  PasswordChangeOnNextLogin: "User must change password on next login",
  ManageMembership: "User Group",
  UserManagement: "User Management",
  EditUserDetails: "Edit User Details",
  ChangePassword: "Change Password",
  UserExists: "User already exists.",
  UserPasswordSuccess: "Password changed successfully",
  PasswordMustBeEightCharacters: "Your password must be at least 8 characters.",
  DeleteLoggedInUser: "You cannot delete the logged in user",
  DependentUsers:
    "Warning:  You are trying to delete a user that has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.",
  SignatureFlowDisabledWarning: "Signature service  is currently disabled for your company. Please enable from company settings.",
  ExportToExcel: "Export to Excel",
  MobileNumberEmpty: "Mobile Number can't be empty",
  invalidOrganizerDownload: "The selected Gather request(s) in the following statuses cannot be downloaded.",
  downloadWaitProcessing: 'Download process may take some time , the downloaded forms will be available in "My Downloads"',
  downloadError: "Download Failed - An error occurred while downloading the selected records. Please try again",
  deleteReturnsError: "We are unable to delete the selected documents.",
  downloadNowWaitProcessing: "Please wait as we are preparing your files",
  bulkDownloadMessageTitle: "Download will initiate only for following organizer statuses:",
  bulkDownloadValidElStatus: "Engagement Letter : Partially Signed, E-signed.",
  bulkDownloadValidOrganizerStatus: "Gather: Partially Completed, Completed.",
  bulkDownloadValidSourceDocumentStatus: "Source Document: Uploaded.",
  DownloadElStatus: "Signature: Awaiting Signature",
  DownloadOrganizerStatus: "Gather: Delivered.",
  DownloadSourceDocumentStatus: "Source Document: Awaiting upload.",
  SigningRelatedStatusNames: "“Awaiting Signature”, “Partially Reviewed”, “Partially Signed”.",
  OrganizerRelatedStatusNames: "“Delivered”, “Partially Completed”, “Questionnaire Completed”.",
  SourceDocRelatedStatusNames: '“Awaiting Upload”, ” Uploaded”, "Downloaded". ',
  AutoReminderSignWarning: "Signing Reminder will be sent for statuses: ",
  AutoReminderOrganizerWarning: "Gather Reminder will be sent for statuses: ",
  DeliveredReporESignWarning:
    'User cannot update reminders for the signature statuses “Reviewed”,“E-signed”, “Downloaded”, “NA”, “Manually Signed"',
  AutoReminderSourceWarning: 'Source Doc. Reminder will be sent for statuses: “Awaiting Upload”, ” Uploaded”, "Downloaded". ',
  DeliveredReportOrganizerWarning:
    "User cannot update reminders for the organizer statuses “Completed”, “Downloaded”, “Manually Completed”",
  AutoReminderIconStatus: "If the status is Downloaded but the TP already Finished the upload step a reminder will not be sent."
};
export const InvalidEmailMessage = "Invalid email address";
export const RequiredFieldError = "This field is required";
export const ErrorMessage = "An unknown error occurred.Please try again ";
export const ChangeStatusError = "Error : Status cannot be changed.";
export const OrganizerClosedError = "Action not permitted as gather is closed.";
export const MyAccountConstants = {
  APIResponse: {
    UserUpdateSuccess: "User updated successfully."
  },
  UserUpdateFailedMessage: "Updating user details failed, please re-login"
};
export const RequestNotification = {
  Failure: {
    AllGathers: "Failed to fetch records.",
    RecycleGathers: "Failed to delete the Gather(s) to Recycle bin.",
    RestoreRecycleGathers: "Failed to restore the Gather(s) from Recycle bin.",
    ArchiveGathers: "Failed to archive the Gather(s).",
    CompanySettings: "Failed to fetch company settings.",
    SendReminderError: "We are unable to send reminder for these documents.",
    UpdateAutoReminderFailed: "Failed to update Auto Reminder",
    DeleteInProcessGatherFailed: "Failed to delete InProcess Gather",
    DeleteInProcessGatherInBatchFailed: "Failed to delete Gather From Batch",
    DeleteInProcessBatchFailed: "Failed to delete InProcess Batch",
    SaveClientInfo: "Failed to save Client Info",
    GetInprocessGather: "Failed to fetch In Process Gather",
    GetInprocessExpandBatch: "Failed to fetch InProcess Expanded Batch",
    GetInprocessBatch: "Failed to fetch InProcess Batch",
    ExportInprocessBatch: "Failed to Export InProcess Batch",
    ExportDeliveredGather: "Failed to Export Delivered Gather",
    ExportUnDeliveredGather: "Failed to Export UnDelivered Gather",
    ExportArchivedGather: "Failed to Export Archived Gather",
    ResendAccessLink: "Failed to send access link.",
    UnlockGather: "Failed to unlock Gather.",
    UnlockOneHub: "Failed to unlock Client Portal.",
    GenerateAccessCode: "Failed to generate access code.",
    GenerateAccessCodeNotSendEmail: "Note: Generating a new code will not send a new email",
    CloseGatherByFirm: "Failed to close Gather.",
    ReopenGatherByFirm: "Failed to reopen Gather.",
    ReopenCompletedDocument: "Failed to reopen Source Document upload for selected Gather.",
    ReopenGather: "Failed to reopen gather for selected client organizer.",
    SaveColumnSetting: "Failed to save report column settings.",
    UserOfficeLocations: "Failed to fetch user office locations",
    UserProfile: "Failed to fetch user profile",
    SafeSendUsersNotInExchangeAndSignature: "Failed to fetch SafeSend users not in Exchange and Signature",
    Partners: "Failed to fetch partners",
    RestoreGathers: "Failed to restore selected Gather(s).",
    CloseOrReopen: "Failed to close/open gather(s).",
    Changestatus: "Failed to change Status for the Gather(s)",
    DownloadGather: "Download Failed - An error occurred while downloading the selected records. Please try again",
    InvalidOTP: "Invalid access code.",
    AccessCode: "Failed to send access code.",
    BatchInfo: "Failed to save Batch Info.",
    GatherInfo: "Failed to save Gather Info",
    DownloadableDocument: "Failed to fetch downloadable documents",
    GatherDRLNames: "Failed to fetch Gather DRL Names",
    ClientTracking: "Failed to fetch Client Tracking",
    SourceDocumentMetaData: "Failed to fetch Source Document Meta Data",
    DownloadSourceDocument: "Failed to download Source Document",
    DownloadSignedEngagementLetter: "Failed to download signed Engagement Letter.",
    DownloadAll: "Failed to download documents",
    DownloadFilledOrganizer: "Failed to download filled Gather.",
    UpdateDRLMapping: "Failed to update DRL Mapping",
    AdditionEsignData: "Failed to fetch additional esign",
    DeliverFailed: "Failed to deliver Gather",
    DownloadFailedUnDelieveredGather: "Failed to download selected undelivered Gather.",
    FailedToCreateDRLName: "Failed to create DRL name",
    PreviewFailed: "Error occured while loading the taxpayer preview",
    GenerateTaxpayerClientViewError: "Error occured while loading the taxpayer client view",
    DeleteUndeliveredGathers: "Failed to delete selected Gather request(s)",
    GetClientInfo: "Failed to fetch client info",
    MFA_OTP_LENGTH: "Failed to fetch MFA OTP length",
    UpdateDRLNotApplicableStatus: "Unable to update the DRL not applicable status",
    UploadDocumentError: "Error occurred while processing uploaded document"
  },
  Success: {
    Success: "Your changes are saved successfully",
    BatchDeliver: "Batch delivered successfully",
    GatherDeliver: "Gather Delivered successfully",
    RecycleGathers: "Successfully deleted selected Gather request(s) to Recycle Bin",
    RestoreRecycleGathers: "Successfully restored selected Gather request(s).",
    Changestatus: "Status has been successfully changed for the Gather request(s).",
    ArchiveGathers: "Successfully archived selected Gather request(s).",
    SendReminderWaitMessage: "We are processing the reminders for emailing. This may take several minutes.",
    UpdateAutoReminder: "Automatic Reminder successfully updated for selected Gather(s).",
    DeleteInProcessGather: "Successfully deleted the selected Gather request.",
    DeleteInProcessGatherInBatch: "Successfully deleted the selected Gather request from batch.",
    DeleteInProcessBatch: "Successfully deleted the selected Gather request from batch",
    ClientInfo: "Successfully saved Client Info",
    GeneralSetting: "Successfully updated Gather company settings.",
    BatchInfo: "Successfully saved Batch Info.",
    ResendAccessLink: "Access link has been sent successfully.",
    ExportInProcessGather: "In Process gather exported successfully.",
    ExportArchievedGather: "Archived gather exported successfully.",
    ExportDeliveredGather: "Delivered gather exported successfully.",
    ExportUnDeliveredGather: "Undelivered gather exported successfully.",
    UnlockGather: "Gather request unlocked successfully!",
    UnlockOneHub: "Client Portal has been unlocked!",
    CloseGatherByFirm: "Selected Gather request(s) is closed successfully.",
    ReopenGatherByFirm: "Closed Gather request(s) is re-opened successfully.",
    ReopenCompletedDocument: "Source Document upload reopened for selected Gather.",
    ReopenGather: "Gather request has been reopened for selected client organizer.",
    ReportColumnSetting: "Failed to fetch report column settings.",
    SaveColumnSetting: "Column Settings saved successfully!",
    RestoreGathers: "Successfully restored the selected Gather request(s).",
    CloseOrReopen: "Successfully closed/opened Gather request(s).",
    DownloadNowWaitProcessing: "Please wait as we are preparing your files.",
    GenerateAccessCode: "Successfully generated Access Code for selected Gather.",
    validOTP: "Access Code Verified successfully.",
    DownloadAllNotification:
      "Download process may take some time, you will receive an email notification when your files are ready to download.",
    DownloadInduvidualNotification: "Download process may take some time,  downloaded forms will be available in 'My Downloads'.",
    DownloadUnDeliveredGather: "Successfully downloaded selected undelivered Gather.",
    PreviewSuccess: "Loaded taxpayer view successfully.",
    GenerateTaxpayerClientViewSuccess: "Loaded taxpayer client view successfully.",
    DownloadWaitProcessing: 'Download process may take some time , the downloaded forms will be available in "My Downloads"',
    DownloadReady: "File is ready for download",
    DeleteUndeliveredGathers: "Successfully deleted selected Gather request(s)"
  }
};

export const ClientInstructionsConstants = {
  Success: {
    Add: "Instruction added successfully",
    Update: "Instruction updated successfully",
    Delete: "Instruction deleted successfully"
  },
  Failure: {
    Add: "Failed to add client instruction",
    Delete: "Failed to delete client instruction",
    Update: "Failed to update client instruction",
    GetRecords: "Failed to fetch client instruction"
  }
};

export const MyDownloadsConstants = {
  Success: {
    Delete: "Successfully deleted the selected zip file(s)",
    ClearAll: "All Downloaded files has been deleted"
  },
  Failure: {
    Delete: "Failed to delete the selected zip file(s)",
    ClearAll: "Failed to delete zip file(s)",
    DownloadZip: "Failed to download zip",
    DownloadAll: "Failed to download all",
    GetMyDownload: "Failed to fetch records"
  }
};
export const EngagementTypeNotification = {
  Failure: {
    FailedToDeleteDefault: "You cannot delete default engagement type",
    FailedToDeleteSystemDefault: "You cannot delete system default engagement type"
  }
}

export const EngagementTypeApiConstants = {
  Success: {
    Add: "Engagement type added successfully",
    Edit: "Engagement type updated successfully",
    Delete: "Engagement type deleted successfully"
  },
  Failure: {
    Get: "Failed to fetch EngagementTypes",
    GetCategories: "Failed to fetch Engagement Categories",
    Add: "Failed to add EngagementType",
    Edit: "Failed to update selected EngagementType",
    Delete: "Failed to delete selected EngagementType"
  }
}
export const GatherTemplateNotification = {
  Failure: {
    FailedToFetch: "Failed to fetch template list",
    FailedToAdd: "Failed to add template",
    FailedToDelete: "Failed to delete template",
    FailedToEdit: "Failed to edit template"
  },
  Warning: {
    TemplateLimitExceede: "Maximum limit of templates is 10",
    NameWarning: "Name cannot be empty",
    CoustumQuestionSelection: "Please select Custom Question",
    RequestDocumentSelection: "Please select Request document",
    StepsSingleSelection: "Please select at least one task option.",
    RequestDocumentSingleSelection: "Please select one more task option"
  },
  Success: {
    EditSuccess: "Your changes are saved successfully",
    AddSuccess: "Template added successfully",
    DeletedSuccess: "Template deleted successfully"
  }
};

export const SavedMessageConstants = {
  Success: {
    Add: "Message added successfully",
    Edit: "Message updated successfully",
    Delete: "Message deleted successfully"
  },
  Failure: {
    Get: "Failed to fetch saved messages",
    Add: "Failed to add message",
    Edit: "Failed to update selected message",
    Delete: "Failed to delete selected message"
  }
};

export const DownloadDocumentsModalConstants = {
  NoDocsMessage: "No files uploaded yet.",
  SettingsDisabledText1: "You're firm admin has turned off Source Document Gathering",
  SettingsDisabledText2: "To enable go to SafeSend Gather Settings.",
  Uploaded: "UPLOADED",
  Extra: "EXTRA",
};
export const SupportedFilePreviewTypes = [".pdf", ".xlsx", ".jpeg", ".png", ".docx", ".jpg"];

export const StoreConstants = {
  Failure: {
    Batch: "Failed to fetch Batch",
    BatchGather: "Failed to fetch Batch Gather",
    GatherClients: "Failed to fetch Client info.",
    Organizer: "Failed to fetch Gather."
  }
};
export const UploadFileWarning = {
  FileSizeLimit: "File size exceeds the maximum limit",
};

export const ValidationConstants = {
  Warning: {
    MultiSelect: "Action not permitted as gather is closed.",
    DeleteMessageWarning: "You cannot delete the default message",
    DeleteInstructionWarning: "You cannot delete the default client instruction."
  },
  Error: {
    RestrictSendReminder: "We cannot send reminder email notification for the selected Gather request(s)."
  }
};

export const ClientManagementConstants = {
  ClientManagementComparisonFailed: "Failed to compare Client Management details.",
  ClientManagementSaveFailed: "Failed to save Client Management details."
};

export const ClientInfoTabConstants = {
  OrganizerInformation: {
    Label: {
      OrganizerInformation: "Gather Information",
      ClientID: "Client ID",
      EngagementType: "Engagement Type",
      TaxYear: "Tax Year",
      OfficeLocation: "Office Location",
      EROSigner: "ERO / Signer"
    },
    Warning: {
      LocationRequired: "Please select office location.",
      ERORequired: "Please select ERO / Signer.",
      TaxYear: "Please select tax year.",
      ContactPersonRequired: "Please select contact person.",
      EngagementTypeRequired: "Please select engagement type.",
      ClientId: "Please enter client ID."
    }
  }
};

export const MyAccountTitle: Record<string, string> = {
  profile: "Profile",
  password: "Password"
};
export const ValidationContants = {
  FirstNameWarning: "Please enter first name.",
  LastNameWarning: "Please enter last name.",
  NameLengthWarning: "Name must have up to 100 characters",
  EmailAdrressWarning: "Please enter email address.",
  ValidEmailAddressWarning: "Please enter valid  email address.",
  ValidIdentityServerEmailAddressWarning:
    "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
  EmailNotMatching: "Email and Confirm Email did not match",
  PhoneNumberWarning: "Please enter the phone number.",
  PhoneNumberLengthWarning: "Please enter a 10 digit phone number.",
  MobileNumberWarning: "Please enter the mobile number.",
  MobileNumberLengthWarning: "Please enter a 10 digit mobile number.",
  CountryCodeWarning: "Please select the country code.",
  PTINWarning: "PTIN should start with P followed by 8 digit number.",
  EnterPasswordWarning: "Please enter password and confirm password.",
  PasswordNotMatchedWarning: "Password and confirmed password did not match.",
  NewPasswordWarning: "Please enter new password.",
  OldPasswordWarning: "Please enter old password",
  ConfirmPasswordWarning: "Please enter confirm password",
  PasswordLengthWarning: "Password must be 8 characters.",
  PasswordWithoutSpaceWarning: "Please enter password without space.",
  ZipWarning: "Please enter zip.",
  ZipFileValidation: "Please upload ZIP files only.",
  ExcelFileValidation: "Please upload Excel files only.",
  ZipLengthWarning: "Please enter a 5 digit zip.",
  ExtensionWarning: "Please enter less than 7 digit extension number.",
  FaxWarning: "Please enter a 10 digit fax number.",
  StateWarning: "Please select state.",
  CityWarning: "Please enter city.",
  ValidCityWarning: "Please enter valid city name.",
  PdfFileValidation: "Please upload Pdf files only.",
  PaymentURLValidation: "Please enter a valid payment URL.",
  FirmKeyWarning: "Please enter valid firm key.",
  AzureGroupIdWarning: "Please enter valid Azure Group Id.",
  PasswordWarning: "Please enter password.",
  K1InstructionNotAVailable: "K1 instruction is not available in the uploaded file",
  NoContentAvailable: "No content available",
  EINWarning: "Please enter valid EIN.",
  FileFormatNotSupported: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
  FileSizeExceeded:
    "Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.",
  UpperCaseValidationError: "Password should contain at least one uppercase character.",
  LowerCaseValidationError: "Password should contain at least one lowercase character.",
  NumbersValidationError: "Password should contain at least one number.",
  SpecialCharactersValidationError: "Password should contain at least one special character.",
  CsvFileValidation: "Please upload Csv files only.",
  SameEmailValidation: "Email Already selected.",
  NotSelectedAnyMessageWarning: "Please select any message.",
  EmptyInputFieldWarning: "This field can't be empty",
  DateInvalidWarning: "Please enter valid date",
  AllClientDeceased: "Cannot deliver gather, all gather client(s) are deceased",
  EmptyDeliveryWarning: "Please select at least one task option 'Signature, Gather or Questionnaire' to proceed to delivery.",
  EmptyDocumentType: "Please select document type to submit"
};

export const UploadtaxReturnConstants = {
  AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
  CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
  DeleteFileMessage: "Are you sure you want to delete this file?",
  CloseConfirmationTitle: "Cancel Upload"
};

export const DeleteUploadedEsignDocument = {
  Title: "Delete File",
  DeleteFileMessage: "Are you sure you want to delete this file?"
}
export const ExportToExcelConstants = {
  Title: "Export to Excel",
  body: "Export process may take some time, you will recieve an email notification when the excel file is available."
};

export const ProfileInformation = {
  TITLE: "Profile Information",
  DESCRIPTION: "View and manage your profile information here.",
  LABEL_TITLE: "Title",
  LABEL_EMAIL: "Email Address",
  LABEL_FIRST_NAME: "First Name",
  LABEL_LAST_NAME: "Last Name",
  LABEL_PHONE_NUMBER: "Phone Number",
  LABEL_EXTENSION: "Extension",
  LABEL_FAX_NUMBER: "Fax Number",
  LABEL_PTIN: "PTIN",
  LABEL_MOBILE_NUMBER: "Mobile Number",
  PLACEHOLDER_TITLE: "Enter Title",
  PLACEHOLDER_EMAIL: "Enter Email Address",
  PLACEHOLDER_FIRST_NAME: "Enter First Name",
  PLACEHOLDER_LAST_NAME: "Enter Last Name",
  PLACEHOLDER_PHONE_NUMBER: "Enter Phone Number",
  PLACEHOLDER_EXTENSION: "Enter Phone Extension",
  PLACEHOLDER_FAX_NUMBER: "Enter Fax Number",
  PLACEHOLDER_PTIN: "Enter PTIN",
  PLACEHOLDER_MOBILE_NUMBER: "Enter Mobile Number",
  VERIFY_TITLE: "Verify Number",
  VERIFY_HELP_TEXT: "You can skip the verification step. On next login, you will be prompted to verify the mobile number",
  PLACEHOLDER_ACCESS_CODE: "Access code",
  ACCESS_CODE_BUTTON: "Verify Code",
  OK_BUTTON: "Save",
  CANCEL_BUTTON: "Cancel",
  FAX_LENGTH: 10,
  PTIN_LENGTH: 9,
  PHONE_NUMBER_LENGTH: 10,
  EXTENSION_LENGTH: 7,
  NAME_LENGTH: 100
};
export const ChangeSecurityConstants = {
  SECURITY_CHANGE_MODAL_TITLE: "Change Password",
  CONFIRM_BUTTON_NAME: "Confirm",
  SECURITY_CHANGE_LOGOUT_WARNING: "Changing your Password will require you to login again. Make the change?",
  SECURITY_UPDATE_SUCCESS: "Password updated successfully.",
  SECURITY_UPDATE_FAILED: "Updating password failed, please re-login",
  PASSWORD_UPDATE_FAILED: "Failed to update user password,please try again."
};
export const DeleteInstructionModal = {
  TITLE: "Delete Instruction",
  BODY: "Are you sure you want to delete this instruction ?"
};

export const ChangePasswordConstants = {
  TITLE: "Change Password",
  DESCRIPTION: "Update your password for SafeSend login here.",
  LABEL_CURRENT_PASSWORD: "Current Password",
  LABEL_NEW_PASSWORD: "New Password",
  LABEL_CONFIRM_PASSWORD: "Confirm Password",
  OK_BUTTON: "Update Password",
  CANCEL_BUTOTN: "Cancel",
  PASSWORD_POLICY_TITLE: "Your password must have:",
  PASSWORD_LENGTH: "Minimum Number of characters: ",
  PASSWORD_REQUIRED: "Required character types: Your password must include one of each of the following character types:",
  PASSWORD_SPECIAL_CHARACTERS: "(View list of available special characters)",
  PASSWORD_CHARACTERS_LIST: "~ ! @ # $ % ^ & * _",
  PLACEHOLDER_CURRENT_PASSWORD: "Enter Current Password",
  PLACEHOLDER_NEW_PASSWORD: "Enter New Password",
  PLACEHOLDER_CONFIRM_PASSWORD: "Repeat New Password"
};

export const UserAutoLogoutModalConstants = {
  PermissionChanged: "Permission Changed",
  LogoutModalMessage: "Your SafeSend permissions have been changed. You must login again for the changes to take effect.",
  Login: "Login"
};

export const signalRConstants = {
  headers: {
    userId: "x-ms-signalr-userid",
    clientType: "ClientType"
  },
  CPA: "CPA",
  GTR: "GTR"
};

export const UiTextConstants = {
  FeatureDisabled: "Feature Disabled",
  AccessDenied: "Access Denied",
  NoProductAccessMessageLine1: "We are sorry, you do not have permission to access Safesend Gather.",
  NoProductAccessMessageLine2: "Please contact your system administrator to update your user profile",
  FeatureDisabledMessageLine1: "This product is not supported on your current subscription tier,",
  FeatureDisabledMessageLine2: "please contact your Customer Success Representative for access.",
  NoPermissionButProductAcessMessageLine1: "Please consult your system",
  NoPermissionButProductAcessMessageLine2: "administrator for assistance.",
  ProductDisabledMessageLine1: "We are sorry, but this feature is currently not available for your use.",
  ProductDisabledMessageLine2: "Please contact your System Administrator for more information."
};
export const More = "More..";

export const ProcessName = {
  Save: "Save",
  Finish: "Finish"
};
export const Logout = {
  Title: "Log Out",
  No: "No, Discard",
  Yes: "Yes, Log Out",
  LogoutConfirmation: "Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite."
}

export const ForeThoughtConstants = {
  WIDGET: "widget",
  WIDGET_MESSAGE: "message",
  WIDGET_STORAGE: "storage",
  WIDGET_VISIBLE: "isForethoughtWidgetVisible",
  WIDGET_LOADED: "forethoughtWidgetLoaded",
  WIDGET_CLOSED: "forethoughtWidgetClosed",
};

export const TableLoaderKeys = [
  'inprocess-gather',
  'inprocess-batch',
  'processing',
  'delivered-gather',
  'recycle',
  'undelivered-gather',
  'archived-gather',
  'RecycledGather',
  'client-view',
  'Export',
  'batch',
  'proforma',
  'CSVData'
];
export const PageLoaderKeys = {
  SavedMessages: ["company-settings", "get-all", "save", "edit", "delete"],
  ClientInstructions: ["company-settings", "GetEmailMessages",
    "AddEmailMessage", "UpdateEmailMessage", "DeleteEmailMessage"],
  General: ["company-settings"],
  CustomQuestions: ["settings", "questionnaire-templates", "questionnaire-forms",
    "set-default"],
  Template: ["gather", "checkAdd", "add", "get-all", "questionnaire-templates"],
  EngagementTypes: ["get-all"],
};
export const EngagementTypeDefault = {
  E1040: "1040",
  None: "None"
}
export const TINYMCE_PROPTYPES_KEY = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
export const DefaultPhoneConstants = {
  CountryCode: "+1",
}
export const LoginHistoryConstants = {
  LoginHistoryTitle: "Login History",
  LastLoginPrompt: "Enable last login prompt",
  LastLoginClose: "Close",
  Browser: "Browser",
  IPaddress: "IP Address",
  LoginDate: "Login Date",
  LogoutDate: "Logout Date",
  CurrentDevice: "Current Device",
  LoggedIn: "Logged In",
  LogInOn: "Log In On",
  LastLoginNote: "Don't show this again",
  CountPerPage: 15,
  PageSize: 10,
  ExpandedPageSize: 15,
  SizePerPageList: [15, 30, 45, 60, 75, 90],
}

export const DateFormatConstants = {
  DEFAULT_DATE_FORMAT: "MM/DD/YYYY",
  TEXT_DATE_FORMAT: "MMMM DD, YYYY",
  REPORTS_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss a",
  LOGIN_TABLE_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss a",
  LAST_LOGIN_DATE_FORMAT: "MM/DD/YYYY hh:mm:ss A",
  SAME_DAY_TIME_FORMAT: "HH:mm",
  CURRENT_WEEK_DATE_FORMAT: "ddd MM-DD",
  MESSAGE_DETAIL_DATE_FORMAT: "ddd MM-DD-YYYY HH:mm",
  DETAIL_DATE_TIME_FORMAT: "MM-DD-YYYY HH:mm A",
  DEFAULT_TIME_FORMAT: "HH:mm:ss A",
  DOCUMENT_EXPIRY_FORMAT: "MM-DD-YYYY",

};
export const UploadFileWarningMessage = (fileCount: number, totalFileCount: number, warningMessage: string) => `Unable to upload ${fileCount} out of ${totalFileCount} file(s), ${warningMessage}`;

export const GatherUploadedDocuments = {
  AllowedFileFormats: ["pdf", "jpg", "png", "jpeg", "xls", "xlsx", "doc", "docx", "qbx", "qbb", "qbm", "qbw"],
  UploadTitle: "Upload File(s)",
  SupportedUploadFileSizeWarningMessage: "Uploaded file(s) is too large. Please upload file(s) less than 500MB.",
  SupportedFilesMessage: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
  SupportedFilesSizeMessage: 'Please upload a file size greater than 0kb.',
  UploadInProgress: "Uploading In Progress...",
  ChooseFileOrDragDrop: "Choose a file or drag and drop here",
  FileTypes: "Please upload file size no more than 500 MB.",
  ChooseFile: "Choose File",
  DocumentDeleted: "Document deleted successfully.",
  DocumentUploaded: "Files uploaded successfully.",
}

export const VERTICAL_SEPARATOR = " | ";

export const PASSWORD_PROTECTION_CHECK_URL = "is-password-protected"

export const GATHER_REPORT_PATH = "GatherReport"